import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import {
  DefaultProfile,
  LogoVoltaHeaderDesktop,
  LogoVoltaHeaderMobile,
} from '@volta-images';
import { usePrevious } from '@volta-utils/hooks';
import { setOpenMenu } from '@volta-component-layout/Header/headerActions';
import paths from './paths';
import './styles.scss';
import { Menu, logout } from '@volta-svg';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import swal from 'sweetalert';
import { logOut, setSession } from '@volta/module/auth/actions';

function Header({ setOpenMenu }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const tabNameRedux = useSelector((state) => state.header.tabName);
  const session = useSelector((state) => state.auth.session);
  const prevTabeNameRedux = usePrevious(tabNameRedux);
  const [tabName, setTabName] = useState(tabNameRedux);
  useEffect(() => {
    for (let i in paths) {
      const r = matchPath(location.pathname, {
        path: i,
        exact: true,
      });
      if (r) {
        setTabName(paths[i]);
        break;
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (prevTabeNameRedux) {
      setTabName(tabNameRedux);
    }
  }, [tabNameRedux]);

  function doLogOut() {
    swal({
      title: 'Apa anda yakin ingin logout?',
      text: 'Anda akan diarahkan ke halaman login',
      icon: 'warning',
      buttons: true,
    }).then((yes) => {
      if (yes) {
        setTimeout(() => {
          dispatch(
            setSession({
              key: 'user',
              data: null,
            })
          );
          dispatch(logOut());
        }, 1000);
      }
    });
  }

  return (
    <div className="header">
      <div className="header-left">
        <div className="logo">
          <img className="desktop" src={LogoVoltaHeaderDesktop} />
          <img className="mobile" src={LogoVoltaHeaderMobile} />
        </div>
        <h1 className="title">{tabName}</h1>
        <button className="btn btn-sm btn-menu" onClick={() => setOpenMenu()}>
          <img src={Menu} />
        </button>
      </div>
      <div className="header-right">
        <h1 className="title">{tabName}</h1>
        <div>
          <div className="profile">
            <Dropdown
              direction="down"
              isOpen={isOpenProfile}
              toggle={() => setIsOpenProfile(!isOpenProfile)}
            >
              <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                aria-expanded={isOpenProfile}
              >
                <div className="d-flex align-items-center">
                  <h5 className="user-name">{session?.user?.name}</h5>
                  <div className="profile-picture ms-2">
                    <img
                      src={
                        session?.user?.image_path
                          ? process.env.REACT_APP_/_GO +
                            session.user.image_path
                          : DefaultProfile
                      }
                    />
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu
                style={{
                  borderRadius: '10px',
                  boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.1)',
                  border: 'none',
                }}
              >
                <DropdownItem onClick={doLogOut}>
                  <img src={logout} className="me-2" width={14} />
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setOpenMenu: (payload) => setOpenMenu(payload),
};

Header.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Header);
