import React from 'react';
import PropTypes from 'prop-types';

export default function Activation({ value, successText, failedText }) {
  let className, text;
  if (value === 'Active' || value === true) {
    className = 'success-text';
    text = successText;
  } else if (value === 'UnActive' || value === false) {
    className = 'failed-text';
    text = failedText;
  }
  return <p className={className}>{text}</p>;
}

Activation.defaultProps = {
  value: 'Active',
  successText: 'Aktif',
  failedText: 'Tidak Aktif',
};

Activation.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  successText: PropTypes.string,
  failedText: PropTypes.string,
};
