import React, { useEffect } from 'react';
import { InputCheckboxV2 } from '@volta-component-input';
import PropTypes from 'prop-types';
import './styles.scss';
import { Label } from 'reactstrap';

export default function InputCheckBoxGroupV2({
  className,
  data,
  title,
  onChange,
  errorText,
}) {
  function onInputCheckSelected(newSelected, index) {
    const newData = data.map((d, i) =>
      i === index ? { ...d, isChecked: newSelected } : d
    );
    onChange(newData[index], newData);
  }

  return (
    <>
      <div className={`form-group ${className}`}>
        <Label className="mb-2">{title}</Label>
        <div className="d-flex gap-3">
          {data.map((item, index) => (
            <InputCheckboxV2
              key={item.id}
              isChecked={item.isChecked}
              onSelectedChange={(newSelected) => {
                onInputCheckSelected(newSelected, index);
              }}
              className="group-checkbox"
              label={item.label}
            />
          ))}
        </div>
        {errorText && (
          <p className="text-danger text-status flex-1">{errorText}</p>
        )}
      </div>
    </>
  );
}

InputCheckBoxGroupV2.defaultProps = {
  className: '',
  data: [],
  title: 'Configuration',
  onChange: () => {},
  errorText: '',
};

InputCheckBoxGroupV2.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(Object),
  title: PropTypes.string,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
};
