var applicationsResponse = (filter) => {
  let filterKey = [];
  let filterValue = '';
  if (filter?.or?.length > 0) {
    for (let filt of filter?.or) {
      filterKey = [...filterKey, Object.keys(filt.applications)[0]];
    }
    filterValue = filter?.or[0]?.applications.applicationName.likeInsensitive;
  }

  const filterApplications = { applications: { ...applications.applications } };
  const filteredApplications = filterApplications.applications.nodes.filter(
    (value) => {
      return value['applicationName']
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    }
  );
  filterApplications.applications.nodes = filteredApplications;
  return filterValue === ''
    ? { data: applications }
    : { data: filterApplications };
};

var applicationDetailResponse = (id = 1) => {
  switch (id) {
    case 1:
      return {
        data: applicationDetailOne,
      };
    case 2:
      return {
        data: applicationDetailTwo,
      };
    case 3:
      return {
        data: applicationDetailThree,
      };

    default:
      return {
        data: applicationDetailOne,
      };
  }
};

var createApplicationResponse = (data) => {
  if (data !== null) {
    data.id = applications.applications.nodes.length + 1;
    applications.applications.nodes = [
      ...applications.applications.nodes,
      data,
    ];
  }
  console.log('applications -> ', applications);
  return {
    data: data,
    meta: metaSuccess,
  };
};

var updateApplicationResponse = (id, data) => {
  return {
    data: data,
    meta: metaSuccess,
  };
};

var applications = {
  applications: {
    nodes: [
      {
        id: 1,
        applicationName: 'VOLTA',
        isActive: true,
        createdBy: 'Nugroho',
        createdDate: '2022-09-30T18:03:29.672556',
      },
      {
        id: 2,
        applicationName: 'SGB',
        isActive: true,
        createdBy: 'Ops_monitoring',
        createdDate: '2022-09-30T18:02:55.680662',
      },
      {
        id: 3,
        applicationName: 'FLEET MANAGEMENT',
        isActive: false,
        createdBy: 'Nugroho',
        createdDate: '2022-09-28T21:42:43.215652',
      },
    ],
    totalCount: 3,
  },
};

var applicationDetailOne = {
  applicationDetail: {
    id: 1,
    applicationName: 'VOLTA',
    isActive: true,
    userName: 'Nugroho',
    createdDate: '2022-09-30T18:03:29.672556',
  },
};

var applicationDetailTwo = {
  applicationDetail: {
    id: 2,
    applicationName: 'SGB',
    isActive: true,
    userName: 'Ops_monitoring',
    createdDate: '2022-09-30T18:02:55.680662',
  },
};

var applicationDetailThree = {
  applicationDetail: {
    id: 3,
    applicationName: 'FLEET MANAGEMENT',
    isActive: false,
    userName: 'Nugroho',
    createdDate: '2022-09-28T21:42:43.215652',
  },
};

var metaSuccess = {
  info: null,
  message: 'Request succesfully proceed',
  success: true,
};

export {
  applicationsResponse,
  applicationDetailResponse,
  createApplicationResponse,
  updateApplicationResponse,
};
