import { Chevron } from '@volta/assets/svg';
import { string } from 'prop-types';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Tooltip } from 'reactstrap';

export default function Status({
  status,
  positiveText,
  negativeText,
  neutralText,
  onButtonVerifyClick,
  withButtonVerify,
  withTooltipVerifyButton,
  tooltipTextVerifyButton,
  withStatusTriggererCTA,
  renderStatusTriggererCTA,
  textStatusTriggererCTA,
  hideStatus,
  renderPositiveCustom,
  renderNegativeCustom,
  renderNeutralCustom,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-1">
          {status === 1 &&
            // <div className="volta-status container-status-positive">
            (hideStatus ? null : renderPositiveCustom ? (
              renderPositiveCustom(positiveText)
            ) : (
              <div
                className={`volta-status container-status-positive${
                  withButtonVerify ? '-withverifybutton' : ''
                }`}
              >
                <p className="text-status-positive">{positiveText}</p>
                {withButtonVerify && (
                  <button
                    disabled
                    className="btn-status-positive"
                    onClick={onButtonVerifyClick}
                  >
                    <img src={Chevron} />
                  </button>
                )}
              </div>
            ))}

          {status === 0 &&
            // <div className="volta-status container-status-negative">
            (hideStatus ? null : renderNegativeCustom ? (
              renderNegativeCustom(negativeText)
            ) : (
              <div
                className={`volta-status container-status-negative${
                  withButtonVerify ? '-withverifybutton' : ''
                }`}
              >
                <p className="text-status-negative">{negativeText}</p>
                {withButtonVerify && (
                  <>
                    <button
                      id="btn-status-negative"
                      className="btn-status-negative"
                      onClick={onButtonVerifyClick}
                    >
                      <img src={Chevron} />
                    </button>
                    {withTooltipVerifyButton && (
                      <Tooltip
                        className="toggle-status-button"
                        placement="top"
                        target="btn-status-negative"
                        isOpen={tooltipOpen}
                        toggle={toggleTooltip}
                      >
                        {tooltipTextVerifyButton}
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            ))}

          {status === 2 &&
            // <div className="volta-status container-status-negative">
            (hideStatus ? null : renderNeutralCustom ? (
              renderNeutralCustom(neutralText)
            ) : (
              <div
                className={`volta-status container-status-neutral${
                  withButtonVerify ? '-withverifybutton' : ''
                }`}
              >
                <p className="text-status-neutral">{neutralText}</p>
                {withButtonVerify && (
                  <>
                    <button
                      id="btn-status-neutral"
                      className="btn-status-neutral"
                      onClick={onButtonVerifyClick}
                    >
                      <img src={Chevron} />
                    </button>
                    {withTooltipVerifyButton && (
                      <Tooltip
                        className="toggle-status-button"
                        placement="top"
                        target="btn-status-neutral"
                        isOpen={tooltipOpen}
                        toggle={toggleTooltip}
                      >
                        {tooltipTextVerifyButton}
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            ))}
        </div>

        {withStatusTriggererCTA && (
          <div>{renderStatusTriggererCTA(textStatusTriggererCTA)}</div>
        )}
      </div>
    </>
  );
}

Status.defaultProps = {
  status: 0,
  positiveText: 'Siap',
  negativeText: 'Belum Siap',
  neutralText: 'Pending',
  onButtonVerifyClick: () => {},
  withButtonVerify: false,
  withTooltipVerifyButton: false,
  tooltipTextVerifyButton: 'your message',
  withStatusTriggererCTA: false,
  renderStatusTriggererCTA: () => {},
  textStatusTriggererCTA: 'status triggerer',
  hideStatus: false,
  renderPositiveCustom: null,
  renderNegativeCustom: null,
  renderNeutralCustom: null,
};

Status.propTypes = {
  status: PropTypes.number,
  positiveText: PropTypes.string,
  negativeText: PropTypes.string,
  onButtonVerifyClick: PropTypes.func,
  withButtonVerify: PropTypes.bool,
  withTooltipVerifyButton: PropTypes.bool,
  tooltipTextVerifyButton: PropTypes.string,
  withStatusTriggererCTA: PropTypes.bool,
  renderStatusTriggererCTA: PropTypes.func,
  textStatusTriggererCTA: PropTypes.string,
  hideStatus: PropTypes.bool,
  renderPositiveCustom: PropTypes.func,
  renderNegativeCustom: PropTypes.func,
  renderNeutralCustom: PropTypes.func,
};
