import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@volta-component-svg';
import './styles.scss';

export default function FormContentMobile({ renderForm, loading }) {
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return <div className="form-content-mobile">{renderForm()}</div>;
}

FormContentMobile.defaultProps = {
  renderForm: () => null,
  loading: false,
};

FormContentMobile.propTypes = {
  renderForm: PropTypes.func,
  loading: PropTypes.bool,
};
