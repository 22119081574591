import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'reactstrap';
import './styles.scss';

export default function InputTextArea({
  className,
  style,
  title,
  id,
  value,
  onValueChange,
  errorText: errorTextProps,
  height,
  placeholder,
  isHorizontalInput,
  isMandatoryLabel,
  maxLength,
  maxLengthCustomWarning,
}) {
  const [errorText, setErrorText] = useState(errorTextProps);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  function onChange(e) {
    const newVal = typeof e === 'string' ? e : e.target.value;
    if (newVal.length > maxLength) {
      if (maxLengthCustomWarning) {
        setErrorText(`${maxLengthCustomWarning(maxLength)}`);
        return;
      }
      setErrorText(`Jumlah maksimal karakter adalah ${maxLength}`);
      return;
    }
    setErrorText('');
    onValueChange(e.target.value);
  }

  return isHorizontalInput ? (
    <div className={className} style={style}>
      <Row>
        <Col className="col-md-2 col-12 align-self-center">
          {title && (
            <label className="label-input-horizontal" htmlFor={id}>
              {title}
            </label>
          )}
          {isMandatoryLabel && (
            <label style={{ marginLeft: '5px', color: '#FF5F5F' }}>*</label>
          )}
        </Col>
        <Col className="col-md-10">
          <Input
            style={{ height: height, marginTop: '10px' }}
            value={value}
            className={`${errorText && 'error-input'}`}
            onChange={onChange}
            type="textarea"
            id={id}
            placeholder={placeholder}
          />
          {errorText && <p className="text-danger text-error">{errorText}</p>}
        </Col>
      </Row>
    </div>
  ) : (
    <div className={className} style={style}>
      {title && <label htmlFor={id}>{title}</label>}
      {isMandatoryLabel && (
        <label style={{ marginLeft: '5px', color: '#FF5F5F' }}>*</label>
      )}
      <Input
        style={{ height: height, marginTop: '10px' }}
        value={value}
        className={`${errorText && 'error-input'}`}
        onChange={onChange}
        type="textarea"
        id={id}
        placeholder={placeholder}
      />
      {errorText && <p className="text-danger text-error">{errorText}</p>}
    </div>
  );
}

InputTextArea.defaultProps = {
  className: '',
  style: {},
  id: '',
  value: '',
  title: '',
  onValueChange: () => {},
  errorText: '',
  placeholder: '',
  isHorizontalInput: false,
  isMandatoryLabel: false,
  maxLength: Infinity,
  maxLengthCustomWarning: null,
};

InputTextArea.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  id: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  title: PropTypes.string,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  isHorizontalInput: PropTypes.bool,
  isMandatoryLabel: PropTypes.bool,
  maxLength: PropTypes.number,
  maxLengthCustomWarning: PropTypes.func,
};
