import VoltaAlert from './VoltaAlertProvider';
import VoltaDialog from './VoltaDialogProvider';

const { VoltaAlertProvider, voltaAlertContext } = VoltaAlert;
const { VoltaDialogProvider, voltaDialogContext } = VoltaDialog;
function Providers() {
  return {
    VoltaAlertProvider,
    VoltaDialogProvider,
    voltaAlertContext,
    voltaDialogContext,
  };
}

export default Providers();
