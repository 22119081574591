import {
  LOG_IN,
  LOG_OUT,
  SET_SESSION,
} from '@volta-redux/extra-constants/auth';

export const logIn = () => ({
  type: LOG_IN,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const setSession = (payload) => ({
  payload,
  type: SET_SESSION,
});
