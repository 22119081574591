import { mock, mockGo } from '..';
import {
  applicationDetailResponse,
  applicationsResponse,
  createApplicationResponse,
  updateApplicationResponse,
} from './mock-responses';

var getApplicationsMock = (filter) => {
  return mock
    .onPost('/graphql', {
      id: 'getApplicationsMock',
      var: {
        first: 10,
        offset: 0,
        filter: filter,
      },
    })
    .reply(200, applicationsResponse(filter));
};

var getApplicationDetailMock = (id = 1) => {
  return mock
    .onPost('/graphql', {
      id: 'getApplicationDetailMock',
      var: {
        id: Number(id),
      },
    })
    .reply(200, applicationDetailResponse(id));
};

var createApplication = (data = null) => {
  const formData = new FormData();
  for (let key in data) {
    const d = data[key];
    formData.append(key, d);
  }
  return mockGo
    .onPost('/application/create', formData)
    .reply(200, createApplicationResponse(data));
};

var updateApplication = (id = null, data = null) => {
  const formData = new FormData();
  for (let key in data) {
    const d = data[key];
    formData.append(key, d);
  }
  return mockGo
    .onPut(`/application/update/${id}`, formData)
    .reply(200, updateApplicationResponse(id, data));
};

const startMockMasterApplicationsServicesApi = () => {
  getApplicationsMock();
  getApplicationDetailMock();
  //   createApplication();
  //   updateApplication();
};

export {
  getApplicationsMock,
  getApplicationDetailMock,
  createApplication,
  updateApplication,
};
export default startMockMasterApplicationsServicesApi;
