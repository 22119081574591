import React from 'react';
import PropTypes from 'prop-types';
import { CheckListWhite } from '@volta-svg';
import './styles.scss';

export default function InputCheckbox({
  className,
  isSelected,
  onSelectedChange,
}) {
  return (
    <div
      onClick={() => {
        onSelectedChange(!isSelected);
      }}
      className={`d-flex justify-content-center align-items-center group-checkbox ${
        isSelected ? 'selected' : ''
      } ${className}`}
    >
      {isSelected && <img src={CheckListWhite} />}
    </div>
  );
}

InputCheckbox.defaultProps = {
  className: '',
  isSelected: false,
  onSelectedChange: () => {},
};

InputCheckbox.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelectedChange: PropTypes.func,
};
