import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export default function Tooltip({ label, tooltipContent }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="volta-tooltip-wrapper"
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
    >
      <span>{label}</span>
      {showTooltip && (
        <span className="volta-tooltip-content">{tooltipContent}</span>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};
