import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { RightArrow } from '@volta-svg';
import './styles.scss';

export default function VoltaModal({
  isOpen,
  toggle,
  renderBody,
  onModalButtonClick,
  title,
  defaultSubmitButton,
  disableDefaultButton,
  submitButtonText,
  size,
  fullscreen,
  centered,
  centeredTitle,
  customClass,
  onClosed,
  unmountOnClose,
  withCloseBtn,
  style,
  styleHeader,
  styleBody,
  isHidden,
}) {
  const modalRef = useRef();
  return (
    <Modal
      ref={modalRef}
      backdrop="static"
      isOpen={isOpen}
      toggle={toggle}
      className={`volta-modal ${customClass}`}
      unmountOnClose={unmountOnClose}
      size={size}
      fullscreen={fullscreen}
      centered={centered}
      onClosed={onClosed}
      hidden={isHidden}
    >
      <ModalBody style={style}>
        <div
          className="volta-modal-header d-flex flex-row align-items-center"
          style={styleHeader}
        >
          {centeredTitle && (
            <h6 className="volta-modal-title-centered m-0 flex-1">{title}</h6>
          )}
          {!centeredTitle && (
            <h6 className="volta-modal-title m-0 flex-1">{title}</h6>
          )}
          {withCloseBtn && (
            <button onClick={toggle} className="btn btn-close" />
          )}
        </div>
        <div className="volta-modal-body" style={styleBody}>
          {renderBody({ toggle, modalRef })}
          {defaultSubmitButton && (
            <button
              disabled={disableDefaultButton}
              onClick={onModalButtonClick}
              className="btn btn-modal-submit"
            >
              {submitButtonText}
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

VoltaModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  renderBody: () => null,
  onModalButtonClick: () => {},
  title: '',
  defaultSubmitButton: true,
  size: '',
  fullscreen: '',
  submitButtonText: 'Simpan Perubahan',
  disableDefaultButton: false,
  centered: true,
  centeredTitle: false,
  customClass: '',
  onClosed: () => {},
  unmountOnClose: false,
  withCloseBtn: true,
  style: {},
  styleHeader: {},
  styleBody: {},
  isHidden: false,
};

VoltaModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  renderBody: PropTypes.func,
  onModalButtonClick: PropTypes.func,
  title: PropTypes.string,
  defaultSubmitButton: PropTypes.bool,
  size: PropTypes.string,
  fullscreen: PropTypes.string,
  submitButtonText: PropTypes.string,
  disableDefaultButton: PropTypes.bool,
  centered: PropTypes.bool,
  centeredTitle: PropTypes.bool,
  customClass: PropTypes.string,
  onClosed: PropTypes.func,
  unmountOnClose: PropTypes.bool,
  withCloseBtn: PropTypes.bool,
  style: {},
  styleHeader: PropTypes.object,
  styleBody: PropTypes.object,
  isHidden: PropTypes.bool,
};
