import React, { useState, useEffect } from 'react';
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import InputText from '../InputText';
import './styles.scss';
import { Search } from '@volta/assets/svg';

export default function InputSelectSearch({
  optionsData,
  selected,
  optionLabel,
  optionValue,
  onOptionSelected,
  onOptionUnselected,
  className,
  disabled,
  title,
  id,
  placeholderSearch,
  placeholderOptions,
  titleOptions,
  withSearching,
  isHorizontalInput,
  isMultiSelect,
  errorText: errorTextProps,
  ...args
}) {
  const [searchText, setSearchText] = useState('');
  const [selection, setSelection] = useState(
    selected ?? isMultiSelect ? [] : ''
  );
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [listOption, setListOption] = useState([]);
  const [errorText, setErrorText] = useState(errorTextProps);

  useEffect(() => {
    setListOption(optionsLabelValue(optionsData));
  }, [optionsData]);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  const optionsLabelValue = (optionsData) => {
    return optionsData.map((option) => {
      return {
        ...option,
        value: optionValue(option),
        label: optionLabel(option),
      };
    });
  };

  const onItemSelected = (item) => {
    if (item !== selection && item !== '') {
      setSelection(item);
    }
    setSearchText('');
    setErrorText('');
    onOptionSelected(item);
  };

  const onMultipleItemSelected = (item) => {
    let newItem = [...selection, item];
    if (!selection.includes(item)) {
      setSelection(newItem);
      onOptionSelected(newItem);
    }
    setErrorText('');
    setSearchText('');
  };

  const handleSearchChange = (val) => {
    setSearchText(val);
  };

  const handleUnselect = () => {
    setListOption(optionsLabelValue(optionsData));
    setSelection('');
    onOptionUnselected();
  };

  const handleMultipleItemUnselect = (value) => {
    let newItem = [...selection];
    newItem.splice(
      newItem.findIndex((i) => i.value === value),
      1
    );
    setSelection(newItem);
    onOptionUnselected(newItem);
  };

  const handleOptionsOpen = () => setOptionsOpen((prevState) => !prevState);

  useEffect(() => {
    //Reset selection when selected props is null or undefined
    if (!selected) setSelection('');
  }, [selected]);

  return isHorizontalInput ? (
    <div className="form-group">
      <Row>
        <Col className="col-md-2 col-12 align-self-center">
          {title && (
            <label htmlFor={id} className="form-label label-input-horizontal">
              {title}
            </label>
          )}
        </Col>
        <Col className="col-md-10">{renderDashboardOptions()}</Col>
      </Row>
      {errorText !== '' && (
        <p className="text-danger text-error mb-0">{errorText}</p>
      )}
    </div>
  ) : (
    <div className="form-group">
      {title && (
        <label htmlFor={id} className="form-label">
          {title}
        </label>
      )}
      {renderDashboardOptions()}
      {errorText !== '' && (
        <p className="text-danger text-error mb-0">{errorText}</p>
      )}
    </div>
  );

  function renderDashboardOptions() {
    const displayOptions = listOption
      .map((item) => {
        if (item.label.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);

    function renderOption(item) {
      if (item === undefined) return 'No result';
      return item.label;
    }

    return (
      <>
        <Dropdown
          isOpen={optionsOpen}
          toggle={handleOptionsOpen}
          disabled={disabled}
          direction="down"
        >
          <DropdownToggle className="select-search" caret>
            {!isMultiSelect ? (
              selection !== '' ? (
                <span className="field-value">{selection.label}</span>
              ) : (
                <span className="field-value">{placeholderOptions}</span>
              )
            ) : selection.length > 0 ? (
              <div className="d-flex flex-wrap gap-2">
                {selection.map((item, index) => (
                  <Badge key={index}>
                    {item.label}
                    <button
                      className="btn btn-close btn-close-sm ms-2"
                      onClick={() => handleMultipleItemUnselect(item.value)}
                    />
                  </Badge>
                ))}
              </div>
            ) : (
              <span className="field-value">{placeholderOptions}</span>
            )}
          </DropdownToggle>
          <DropdownMenu className="select-search-menu" {...args}>
            {withSearching && (
              <DropdownItem text>
                <div className="search-input">
                  <img src={Search} />
                  <InputText
                    title=" "
                    placeholder={placeholderSearch}
                    onValueChange={(val) => {
                      handleSearchChange(val);
                    }}
                    value={searchText}
                  />
                </div>
              </DropdownItem>
            )}
            <DropdownItem header className="title-options">
              {titleOptions}
            </DropdownItem>
            {displayOptions.length > 0 ? (
              displayOptions.map((item, index) => {
                return (
                  <DropdownItem
                    key={`option-item-${index}`}
                    onClick={(e) => {
                      if (isMultiSelect) onMultipleItemSelected(item);
                      else onItemSelected(item);
                    }}
                  >
                    {renderOption(item)}
                  </DropdownItem>
                );
              })
            ) : (
              <DropdownItem header className="txt-no-data">
                No result
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }
}

InputSelectSearch.defaultProps = {
  optionsData: [],
  optionLabel: 'label',
  optionValue: 'value',
  onOptionSelected: () => {},
  onOptionUnselected: () => {},
  className: '',
  disabled: false,
  title: '',
  id: '',
  placeholderSearch: 'pencarian',
  placeholderOptions: '--- silahkan pilih ---',
  titleOptions: 'silahkan pilih',
  withSearching: true,
  isHorizontalInput: false,
  isMultiSelect: false,
  errorText: '',
};

InputSelectSearch.propTypes = {
  optionsData: PropTypes.arrayOf(Object).isRequired,
  optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(Object),
    PropTypes.number,
  ]),
  onOptionSelected: PropTypes.func,
  onOptionUnselected: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  placeholderSearch: PropTypes.string,
  placeholderOptions: PropTypes.string,
  titleOptions: PropTypes.string,
  withSearching: PropTypes.bool,
  isHorizontalInput: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  errorText: PropTypes.string,
};
