import { Loading } from '@volta/component/svg';
import React from 'react';
import VoltaModal from '@volta-component/layouts/VoltaModal';
import PropTypes from 'prop-types';
import './styles.scss';
import { Image } from '@volta/component/cards';
import { Question } from '@volta/assets/svg';

export const IconDialog = ({
  isOpen,
  toggle,
  onClosed,
  withTitle,
  title,
  description,
  withDesc,
  isLoading,
  textPositiveAnswer,
  textNegativeAnswer,
  onPositiveAnswered,
  onNegativeAnswered,
  unmountOnClose,
  withCloseBtn,
  titleStyle,
  descStyle,
  icon,
}) => {
  function renderBody() {
    if (isLoading) {
      return <Loading />;
    } else {
      return (
        <div className="body-icon-dialog">
          <Image src={icon} className="mb-4" />
          {withTitle && (
            <h5 className="title-modal mt-0" style={titleStyle}>
              {title}
            </h5>
          )}
          {withDesc && (
            <h5 className="desc-modal" style={descStyle}>
              {description}
            </h5>
          )}
          <div className="d-flex flex-row submit-content">
            <button
              className="d-flex btn btn-cancel align-items-center justify-content-center"
              onClick={onNegativeAnswered}
            >
              <p>{textNegativeAnswer}</p>
            </button>
            <button
              onClick={onPositiveAnswered}
              className="d-flex btn btn-submit align-items-center justify-content-center"
            >
              <p>{textPositiveAnswer}</p>
            </button>
          </div>
        </div>
      );
    }
  }
  return (
    <VoltaModal
      isOpen={isOpen}
      toggle={toggle}
      defaultSubmitButton={false}
      renderBody={() => renderBody()}
      customClass="icon-dialog"
      onClosed={onClosed}
      unmountOnClose={unmountOnClose}
      withCloseBtn={withCloseBtn}
    />
  );
};

IconDialog.defaultProps = {
  isOpen: false,
  toggle: () => {},
  onClosed: () => {},
  title: 'Apakah anda yakin ingin melakukan perubahan?',
  description:
    'dengan melakukan perubahan, anda tidak dapat memulihkan kembali',
  withTitle: true,
  withDescription: true,
  isLoading: false,
  textPositiveAnswer: 'Ya',
  textNegativeAnswer: 'Tidak',
  onPositiveAnswered: () => {},
  onNegativeAnswered: () => {},
  unmountOnClose: false,
  withCloseBtn: true,
  titleStyle: {},
  descStyle: {},
  icon: Question,
};

IconDialog.propTypes = {
  isOpen: PropTypes.bool,
  withTitle: PropTypes.bool,
  toggle: PropTypes.func,
  onClosed: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  withDesc: PropTypes.string,
  isLoading: PropTypes.bool,
  textPositiveAnswer: PropTypes.string,
  textNegativeAnswer: PropTypes.string,
  onPositiveAnswered: PropTypes.func,
  onNegativeAnswered: PropTypes.func,
  unmountOnClose: PropTypes.bool,
  withCloseBtn: PropTypes.bool,
  titleStyle: PropTypes.object,
  descStyle: PropTypes.object,
  icon: PropTypes.string,
};
