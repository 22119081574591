const dataUserUpgradeVoltaPlus = [
  {
    id: 28,
    product_name: 'SCB',
    product_id: 3,
    account_number: '6285779088431',
    username: 'Adam Rabbani',
    user_id: 77,
    police_number: 'BG 0016 PAG',
    qouta: 200,
    quota_remainder: 196,
    is_active: true,
  },
  {
    id: 27,
    product_name: 'SCB',
    product_id: 3,
    account_number: '6285779088431',
    username: 'Adam Rabbani',
    user_id: 77,
    police_number: 'B 4734 SXI',
    qouta: 200,
    quota_remainder: 0,
    is_active: false,
  },
  {
    id: 26,
    product_name: 'SCB',
    product_id: 3,
    account_number: '6281388155901',
    username: 'Satrio',
    user_id: 74,
    police_number: 'F-123ABC',
    qouta: 200,
    quota_remainder: 185,
    is_active: true,
  },
  {
    id: 25,
    product_name: 'SCB',
    product_id: 3,
    account_number: 'alvimuh',
    username: 'Alvi Muh',
    user_id: 377,
    police_number: 'B1234HAHA',
    qouta: 200,
    quota_remainder: 200,
    is_active: false,
  },
  {
    id: 24,
    product_name: 'SCB',
    product_id: 3,
    account_number: 'alvimuh',
    username: 'Alvi Muh',
    user_id: 377,
    police_number: 'B1234HAHA',
    qouta: 200,
    quota_remainder: 200,
    is_active: false,
  },
  {
    id: 23,
    product_name: 'SCB',
    product_id: 3,
    account_number: '6285779088431',
    username: 'Adam Rabbani',
    user_id: 77,
    police_number: 'BG 0016 PAG',
    qouta: 1,
    quota_remainder: 0,
    is_active: false,
  },
  {
    id: 22,
    product_name: 'Booking',
    product_id: 1,
    account_number: '6281388155901',
    username: 'Satrio',
    user_id: 74,
    police_number: 'F-123ABC',
    qouta: 200,
    quota_remainder: 198,
    is_active: false,
  },
  {
    id: 21,
    product_name: 'SCB',
    product_id: 3,
    account_number: '6281388155901',
    username: 'Satrio',
    user_id: 74,
    police_number: 'F-123ABC',
    qouta: 200,
    quota_remainder: 0,
    is_active: false,
  },
  {
    id: 20,
    product_name: 'SCB',
    product_id: 3,
    account_number: '6281388155901',
    username: 'Satrio',
    user_id: 74,
    police_number: 'F-123ABC',
    qouta: 200,
    quota_remainder: 200,
    is_active: false,
  },
  {
    id: 19,
    product_name: 'Tukar Titip',
    product_id: 2,
    account_number: '628999382541',
    username: 'Arsamenawi',
    user_id: 567,
    police_number: 'B 1000 TEST',
    qouta: 200,
    quota_remainder: 200,
    is_active: false,
  },
];

const dataUserUpgradeVoltaPlusEmpty = [];
export { dataUserUpgradeVoltaPlus, dataUserUpgradeVoltaPlusEmpty };
