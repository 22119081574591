import React from 'react';
import { VoltaModal } from '@volta-component-layout';
import PropTypes from 'prop-types';
import './styles.scss';

export default function FilterModal({
  isOpen,
  toggle,
  renderFilterContent,
  onClosePress,
}) {
  return (
    <VoltaModal
      isOpen={isOpen}
      title="Filter"
      toggle={toggle}
      defaultSubmitButton={false}
      // onModalButtonClick={() => setShowDetailModal(false)}
      // defaultSubmitButton={false}
      renderBody={renderFilterContent}
      centered={true}
      centeredTitle={true}
      fullscreen="md-down"
      style={{ padding: '30px 20px 40px' }}
      styleBody={{ margin: 0 }}
    />
  );
}

FilterModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  renderFilterContent: () => {},
  onClosePress: () => {},
};

FilterModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  renderFilterContent: PropTypes.func,
  onClosePress: PropTypes.func,
};
