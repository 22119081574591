import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useFileSelector } from '@volta-utils/hooks';
import { /_GO } from '@volta-redux/api';
import './styles.scss';

export default function InputImage({
  className,
  btnTitle,
  onFileSelected,
  src,
  errorRes,
  title,
  temporaryImageUrl,
}) {
  const fileInput = useFileSelector();
  const [fileUrl, setFileUrl] = useState(src);
  const [fileBlob, setFileBlob] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    // console.log('img', { src });
    if (typeof src === 'string' && src.indexOf(/_GO) !== -1) {
      axios
        .get(src, {
          responseType: 'blob',
          headers: {
            apikey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
          },
        })
        .then((response) => {
          // console.log('image res', response);
          const imageData = response.data;
          const imageUrl = URL.createObjectURL(imageData);
          setFileUrl(imageUrl);
        })
        .finally(() => {
          setImageLoading(false);
        });
      setImageLoading(true);
    } else {
      setFileBlob(src);
    }
  }, [src]);

  useEffect(() => {
    temporaryImageUrl(fileUrl);
  }, [fileUrl]);

  fileInput.accept = 'image/png, image/jpeg, iamge/jpg';

  fileInput.onchange = (e) => {
    const file = e.target.files[0];
    if (!file || file?.type?.indexOf('image') === -1) {
      alert('Mohon masukan file gambar');
      return;
    }
    const filereader = new FileReader();
    filereader.readAsArrayBuffer(file);
    filereader.onload = (readerEvent) => {
      const content = readerEvent.target.result;
      const blobContent = new Blob([content]);
      console.log('content -> ', content);
      console.log('blob content -> ', blobContent);
      setFileBlob(blobContent);
      setFileUrl(URL.createObjectURL(blobContent));
      onFileSelected({ fileBlob, file, fileUrl });
    };
  };

  function renderImage() {
    if (imageLoading) {
      return <p className="text-danger">Mohon tunggu, gambar sedang dimuat</p>;
    }
    return typeof fileUrl === 'string' && fileUrl ? (
      <img src={fileUrl} className="image" />
    ) : (
      <p style={{ textAlign: 'center' }} className="text-primary">
        Klik tombol dibawah untuk memasukan gambar
      </p>
    );
  }

  return (
    <div className={className}>
      <label className="form-label">{title}</label>
      <div
        className={`input-image d-flex align-items-center justify-content-center ${
          errorRes ? 'error' : ''
        }`}
      >
        {renderImage()}
      </div>
      {errorRes && <span className="mt-3 text-danger">{errorRes}</span>}
      <div className="mt-4">
        <button
          onClick={() => fileInput.click()}
          className="btn btn-upload ms-auto d-block"
        >
          {btnTitle}
        </button>
      </div>
    </div>
  );
}

InputImage.defaultProps = {
  className: '',
  btnTitle: 'Unggah Gambar',
  errorRes: '',
  src: '',
  title: '',
  onFileSelected: () => {},
  temporaryImageUrl: () => {},
};

InputImage.propTypes = {
  className: PropTypes.string,
  errorRes: PropTypes.string,
  btnTitle: PropTypes.string,
  onFileSelected: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
  temporaryImageUrl: PropTypes.func,
};
