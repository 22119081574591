import './styles.scss';
import { Stepper } from 'react-form-stepper';
import { useWindowSize } from '@volta/utils/hooks';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

export default function VoltaStepper({
  steps,
  onNextStep,
  onBackStep,
  onSubmit,
  children,
  submitButtonText,
  nextStepButtonText,
  isBackButton,
}) {
  const [windowSize] = useWindowSize();
  const isDesktop = windowSize > 1024;
  let [activeStep, setActiveStep] = useState(0);
  let childrens = React.Children.toArray(children);

  function toNextStep(activeStep, goNextStep) {
    onNextStep(activeStep, goNextStep);
  }

  function goNextStep() {
    if (activeStep < steps.length - 1) {
      activeStep += 1;
      setActiveStep(activeStep);
    }
  }

  function toBackStep(activeStep, goBackStep) {
    onBackStep(activeStep, goBackStep);
  }

  function goBackStep() {
    if (activeStep > 0) {
      activeStep -= 1;
      setActiveStep(activeStep);
    }
  }

  function toSubmit() {
    onSubmit();
  }

  function isLastStep() {
    if (activeStep === steps.length - 1) {
      return true;
    }
    return false;
  }

  function isMiddleStep() {
    if (activeStep > 0 && activeStep < steps.length - 1) {
      return true;
    }
    return false;
  }

  function isFirstStep() {
    if (activeStep === 0) {
      return true;
    }
    return false;
  }

  function renderBackSubmitButton() {
    return (
      <>
        {isDesktop && (
          <Row className="mt-4">
            <Col className="d-flex justify-content-end">
              <button
                onClick={() => toBackStep(activeStep, goBackStep)}
                className="btn btn-stepper-back d-flex align-items-center justify-content-center"
              >
                Kembali
              </button>
              <button
                onClick={() => toSubmit()}
                className="btn btn-stepper d-flex align-items-center justify-content-center ms-4"
              >
                {submitButtonText}
              </button>
            </Col>
          </Row>
        )}
        {!isDesktop && (
          <Row className="mt-4">
            <Col className="d-flex flex-column justify-content-center ms-4 me-4">
              <button
                onClick={() => toBackStep(activeStep, goBackStep)}
                className="btn btn-stepper-back d-flex align-items-center justify-content-center"
              >
                Kembali
              </button>
              <button
                onClick={() => toSubmit()}
                className="btn btn-stepper d-flex align-items-center justify-content-center mt-3"
              >
                {submitButtonText}
              </button>
            </Col>
          </Row>
        )}
      </>
    );
  }

  function withBackButton() {
    if (isFirstStep()) {
      return renderNextButton();
    }
    if (isMiddleStep()) {
      return renderBackNextButton();
    }
    if (isLastStep()) {
      return renderBackSubmitButton();
    }
  }

  function withoutBackButton() {
    if (!isLastStep()) {
      return renderNextButton();
    }
    if (isLastStep()) {
      return renderSubmitButton();
    }
  }

  function renderBackNextButton() {
    return (
      <>
        {isDesktop && (
          <Row className="mt-0 mb-3">
            <Col className="d-flex justify-content-end">
              <button
                onClick={() => toBackStep(activeStep, goBackStep)}
                className="btn btn-stepper-back d-flex align-items-center justify-content-center"
              >
                Kembali
              </button>
              <button
                onClick={() => toNextStep(activeStep, goNextStep)}
                className="btn btn-stepper d-flex align-items-center justify-content-center ms-4"
              >
                {nextStepButtonText}
              </button>
            </Col>
          </Row>
        )}
        {!isDesktop && (
          <Row className="mt-0 mb-3">
            <Col className="d-flex flex-column justify-content-center ms-4 me-4">
              <button
                onClick={() => toBackStep(activeStep, goBackStep)}
                className="btn btn-stepper-back d-flex align-items-center justify-content-center"
              >
                Kembali
              </button>
              <button
                onClick={() => toNextStep(activeStep, goNextStep)}
                className="btn btn-stepper d-flex align-items-center justify-content-center mt-3"
              >
                {nextStepButtonText}
              </button>
            </Col>
          </Row>
        )}
      </>
    );
  }

  function renderNextButton() {
    return (
      <>
        <Row className="mt-0 mb-3">
          <Col
            className={`d-flex ${
              isDesktop ? 'justify-content-end' : 'justify-content-center'
            }`}
          >
            <button
              onClick={() => toNextStep(activeStep, goNextStep)}
              className="btn btn-stepper d-flex align-items-center justify-content-center ms-4"
            >
              {nextStepButtonText}
            </button>
          </Col>
        </Row>
      </>
    );
  }

  function renderSubmitButton() {
    return (
      <>
        <Row className="mt-4">
          <Col className="d-flex justify-content-end">
            <button
              onClick={() => toSubmit()}
              className="btn btn-stepper d-flex align-items-center justify-content-center"
            >
              {submitButtonText}
            </button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Stepper
        // style={{ background: 'yellow' }}
        steps={steps}
        activeStep={activeStep}
        connectorStateColors={true}
        styleConfig={{
          activeBgColor: '#126235',
          completedBgColor: '#ffffff',
          completedTextColor: '#126235',
          inactiveTextColor: '#126235',
          activeTextColor: '#ffffff',
          circleFontSize: '13px',
          inactiveBgColor: '#F0F0F0',
        }}
        connectorStyleConfig={{
          completedColor: '#126235',
          activeColor: '#126235',
          disabledColor: '#E1E1E1',
        }}
        className="volta-stepper"
        stepClassName="volta-step"
      />
      <div className="volta-container-step">
        {childrens[activeStep]}
        {isBackButton && withBackButton()}
        {!isBackButton && withoutBackButton()}
      </div>
    </>
  );
}

VoltaStepper.defaultProps = {
  submitButtonText: 'Simpan Perubahan',
  nextStepButtonText: 'Selanjutnya',
  isBackButton: false,
};
