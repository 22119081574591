import {
  dataUserUpgradeVoltaPlus,
  dataUserUpgradeVoltaPlusEmpty,
} from './data-responses';

var getUserUpgradeVoltaPlusResponse = () => {
  return {
    data: dataUserUpgradeVoltaPlus,
    meta: metaSuccessList,
  };
};

var savePicPickupBatteryResponse = (data) => {
  return {
    data: data,
    meta: metaSuccessPost,
  };
};

var metaInfoList = {
  page: 1,
  page_size: 10,
  pages: 3,
  count: 23,
  more_records: true,
};

var metaInfoListEmpty = {
  page: 1,
  page_size: 10,
  pages: 1,
  count: 0,
  more_records: false,
};

var metaSuccessList = {
  info: metaInfoList,
  message: 'Request succesfully proceed',
  success: true,
};

var metaSuccessListEmpty = {
  info: metaInfoListEmpty,
  message: 'Request succesfully proceed',
  success: true,
};

var metaSuccessPost = {
  info: null,
  message: 'Request succesfully proceed',
  success: true,
};

export { getUserUpgradeVoltaPlusResponse, savePicPickupBatteryResponse };
