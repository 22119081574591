import { SET_PROVINCE } from '../extra-constants/province';

const provinceInitialStates = {
  data: [],
  action: '',
};

function provinceReducer(state = provinceInitialStates, action) {
  const { payload, type } = action;
  const actions = {
    [SET_PROVINCE]: () => ({
      ...state,
      data: payload,
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { provinceReducer };
