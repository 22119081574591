import { mock, mockGo } from '..';
import {
  createNotificationResponse,
  generalNotificationResponse,
  gnrNotificationDetailResponse,
} from './mock-response';

var getGnrNotificationMock = (filter) => {
  return mock
    .onPost('/graphql', {
      id: 'getGnrNotification',
      var: {
        first: 10,
        offset: 0,
        filter: filter,
      },
    })
    .reply(200, generalNotificationResponse(filter));
};

var getDetailGnrNotificationMock = (id = 1, filter) => {
  return mock
    .onPost('/graphql', {
      id: 'getGnrNotificationDetail',
      var: {
        id: Number(id),
        first: 10,
        offset: 0,
        filter: filter,
      },
    })
    .reply(200, gnrNotificationDetailResponse(id, filter));
};

var createNotification = (data = null) => {
  return mockGo
    .onPost('/gnr-notification', data)
    .reply(200, createNotificationResponse(data));
};

const startMockBroadcastNotificationServicesApi = () => {
  getGnrNotificationMock();
  getDetailGnrNotificationMock();
};

export {
  getGnrNotificationMock,
  getDetailGnrNotificationMock,
  createNotification,
};

export default startMockBroadcastNotificationServicesApi;
