import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebounce, useWindowSize } from '@volta-utils/hooks';
import { Eye, IconSuccess, EyeOpen, EyeClosed } from '@volta-svg';
import { LoadingOnText } from '@volta-component-svg';
import './styles.scss';
import { Image, Status } from '@volta/component/cards';
import { Col, Row, Tooltip } from 'reactstrap';

export const REGEX_PHONE = /^\+?(\d)*$/;
export const FIRST_NUMERIC_REGEX = /[-0-9]/;
export const NUMERIC_REGEX = /\d+/;
export const NUMERIC_REGEX_ALTERNATIVE = /^(0|[1-9][0-9]*)$/;
export const EMAIL_REGEX = /(^$|^.*@.*\..*$)/;

export default function InputText(props) {
  const {
    title,
    id,
    type,
    value,
    onValueChange,
    className,
    inputClassName,
    style,
    wrapped,
    disabled,
    errorText: errorTextProps,
    onKeyDown,
    isAutoComplete,
    autoCompleteData,
    autoCompleteLabelField,
    autoCompleteKeyField,
    autoCompleteDataSelected,
    autoCompleteDataCleanUp,
    autocomplateOnAfterTyped,
    autoCompleteLoading,
    autoCompleteListTop,
    maxLength,
    minLength,
    usePropErrorRes,
    displayViewPassword,
    displayVerifyButton,
    onVerifyButtonClick,
    filterErrorText,
    statusVerify,
    disabledVerifyButton,
    displayUrlInput,
    withTooltip,
    tooltipText,
    displayResetPassword,
    onResetPasswordClick,
    textBtnReset,
    positiveResetText,
    statusReset,
    placeholder,
    isMandatoryLabel,
    maxLengthCustomWarning,
    minLengthCustomWarning,
    numericCustomWarning,
    isDangerIndicator: isDangerIndicatorProps,
    isWarningIndicator: isWarningIndicatorProps,
    onBlur: onBlurProps,
    isHorizontalInput,
    ...rest
  } = props;
  const [errorText, setErrorText] = useState(errorTextProps);
  const [stateVal, setStateVal] = useState(value);
  const [isFocus, setFocused] = useState(false);
  const [typeState, setTypeState] = useState(
    displayUrlInput ? 'button' : 'password'
  );
  const onAutocompleteTextType = useDebounce(afterAutocompleteType);
  const [isLoadingVerifyStatus, setIsLoadingVerifyStatus] = useState(false);
  const inputRef = useRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [statusResetState, setStatusResetState] = useState(statusReset);
  const [isLoadingResetStatus, setIsLoadingResetStatus] = useState(false);
  const [isDangerIndicator, setIsDangerIndicator] = useState(
    isDangerIndicatorProps
  );
  const [isWarningIndicator, setIsWarningIndicator] = useState(
    isWarningIndicatorProps
  );

  const [windowSize] = useWindowSize();
  const isDesktop = windowSize > 1024;

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  useEffect(() => {
    setIsDangerIndicator(isDangerIndicatorProps);
  }, [isDangerIndicatorProps]);

  useEffect(() => {
    if (isDangerIndicator) {
      applyDangerIndicator();
    }
  }, [isDangerIndicator]);

  useEffect(() => {
    setIsWarningIndicator(isWarningIndicatorProps);
  }, [isWarningIndicatorProps]);

  useEffect(() => {
    if (isWarningIndicator) {
      applyWarningIndicator();
    }
  }, [isWarningIndicator]);

  useEffect(() => {
    if (isAutoComplete) {
      setStateVal(value);
    }
    // if (errorText) {
    //   console.log('value change errorText', { value });
    //   setErrorText('');
    // }
  }, [value]);

  useEffect(() => {
    if (isAutoComplete && autoCompleteData.length > 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoCompleteData]);

  useEffect(() => {
    if (!isFocus) {
      releaseIndicator();
    } else {
      if (!isWarningIndicator) focusIndicator();
    }
  }, [isFocus]);

  function afterAutocompleteType(e) {
    if (e.target.value !== '') {
      autocomplateOnAfterTyped(e);
    } else {
      autoCompleteDataCleanUp();
    }
  }

  function onErrorTextChange(errMessage, mustChange = true) {
    let _errMessage = errMessage;
    if (filterErrorText && mustChange && errMessage) {
      if (typeof filterErrorText === 'string') {
        _errMessage = filterErrorText;
      } else if (typeof filterErrorText === 'function') {
        _errMessage = filterErrorText(props);
      }
    }
    setErrorText(_errMessage);
  }

  function setValueCompose(e, newVal) {
    if (isAutoComplete) {
      setStateVal(newVal);
      onAutocompleteTextType(e);
    } else {
      onValueChange(newVal);
    }
  }

  function onChange(e) {
    const newVal = typeof e === 'string' ? e : e.target.value;
    if (newVal.length > maxLength) {
      if (maxLengthCustomWarning) {
        onErrorTextChange(`${maxLengthCustomWarning(maxLength)}`, false);
        return;
      }
      onErrorTextChange(`Maximal character is ${maxLength}`, false);
      return;
    } else if (newVal.length < minLength) {
      if (minLengthCustomWarning) {
        onErrorTextChange(`${minLengthCustomWarning(minLength)}`);
        setValueCompose(e, newVal);
        return;
      }
      onErrorTextChange(`Minimal character is ${minLength}`);
      setValueCompose(e, newVal);
      return;
    }
    if (type === 'phone') {
      if (newVal?.length > 0 && !REGEX_PHONE.test(newVal)) {
        onErrorTextChange('Input must be phone number');
        return;
      }
    } else if (type === 'decimal') {
      const t = Number(newVal);
      if (
        (newVal?.length > 1 && Number.isNaN(t)) ||
        (newVal.length === 1 && !FIRST_NUMERIC_REGEX.test(newVal))
      ) {
        onErrorTextChange('Input must be decimal number');
        return;
      }
    } else if (type === 'numeric') {
      if (!NUMERIC_REGEX.test(newVal)) {
        if (numericCustomWarning) {
          onErrorTextChange(numericCustomWarning());
          return;
        }
        onErrorTextChange('Input must be numeric');
        return;
      }
    } else if (type === 'numeric_alternative') {
      if (!NUMERIC_REGEX_ALTERNATIVE.test(newVal)) {
        if (numericCustomWarning) {
          onErrorTextChange(numericCustomWarning());
          return;
        }
        onErrorTextChange('Input must be numeric');
        return;
      }
    } else if (type === 'email') {
      if (!EMAIL_REGEX.test(newVal)) {
        if (numericCustomWarning) {
          onErrorTextChange(numericCustomWarning());
          setValueCompose(e, newVal);
          return;
        }
        onErrorTextChange('Format email belum valid');
        setValueCompose(e, newVal);
        return;
      }
    }
    onErrorTextChange('');
    setValueCompose(e, newVal);
    focusIndicator();
  }

  function getInputValue() {
    if (isAutoComplete) {
      return stateVal;
    }
    return value;
  }

  function getErrorText() {
    return usePropErrorRes ? errorTextProps : errorText;
  }

  function toResetPasswordClick() {
    onResetPasswordClick(setIsLoadingResetStatus, setStatusResetState);
  }

  function generateStatusText() {
    if (displayVerifyButton) {
      if (statusVerify() === 0) {
        return <Status status={0} />;
      } else if (statusVerify() === 1) {
        return <Status status={1} />;
      } else {
        return;
      }
    }

    if (displayResetPassword) {
      // if (statusResetState !== 0 && statusResetState !== 1) return;
      return (
        <Status
          hideStatus={
            statusResetState !== 0 && statusResetState !== 1 ? true : false
          }
          status={statusResetState}
          positiveText={positiveResetText}
          negativeText="ada kesalahan"
          withStatusTriggererCTA
          textStatusTriggererCTA={textBtnReset}
          renderStatusTriggererCTA={(textStatusTriggerer) => {
            return (
              <>
                <a
                  className="cta-reset-password"
                  onClick={toResetPasswordClick}
                >
                  {textStatusTriggerer}
                </a>
              </>
            );
          }}
          renderPositiveCustom={(positiveText) => {
            return (
              <div className="d-flex flex-row status-card-positive">
                <Image
                  src={IconSuccess}
                  alt="success-status-img"
                  style={{ marginRight: '10px' }}
                />
                <text className="text-card-positive">{positiveText}</text>
              </div>
            );
          }}
        />
      );
    }
  }

  function getStatusText() {
    if (displayVerifyButton) {
      return displayVerifyButton ? (
        isLoadingVerifyStatus ? (
          <LoadingOnText width="20px" height="20px" display="inline" />
        ) : (
          generateStatusText()
        )
      ) : null;
    }

    if (displayResetPassword) {
      return displayResetPassword ? (
        isLoadingResetStatus ? (
          <LoadingOnText width="20px" height="20px" display="inline" />
        ) : (
          generateStatusText()
        )
      ) : null;
    }
    // return displayVerifyButton ? (
    //   <LoadingOnText width="20px" height="20px" display="inline" />
    // ) : null;
  }

  function onFocus(e) {
    if (e.target.value !== '') {
      autocomplateOnAfterTyped(e);
    }
    setFocused(true);
  }

  function onBlur() {
    setTimeout(() => {
      if (displayUrlInput) setTypeState('button');
      onBlurProps();
      if (!isWarningIndicator) setFocused(false);
    }, 200);
  }

  function onClick(e) {
    if (displayUrlInput) {
      let isDClick = isDoubleClick(e);
      typeState === 'text'
        ? isPreventSingleClickAction('set', true)
        : isPreventSingleClickAction('set', isDClick);
      setTimeout(() => {
        if (!isDClick) {
          if (!isPreventSingleClickAction('get')) {
            return window.open(getInputValue(), '_blank');
          }
        }
        setTypeState('text');
      }, 300);
    }
  }

  const isPreventSingleClickAction = (function () {
    if (displayUrlInput) {
      let isPrevent = false;
      return function (action, value) {
        switch (action) {
          case 'get':
            return isPrevent;
          case 'set': {
            isPrevent = value;
            return isPrevent;
          }
          default:
            return isPrevent;
        }
      };
    }
  })();

  const isDoubleClick = (function () {
    return function (event) {
      if (event.detail === 2) return true;
      else return false;
    };
  })();

  function toVerifyButtonClick() {
    console.log('verify button click');
    // setIsLoading(!isLoading);
    onVerifyButtonClick(setIsLoadingVerifyStatus);
  }

  function applyDangerIndicator() {
    inputRef.current.style.border = '1px solid #FF5353';
  }

  function applyWarningIndicator() {
    inputRef.current.style.border = '1px solid #f0ad4e';
  }

  function releaseIndicator() {
    inputRef.current.style.border = 'none';
  }

  function focusIndicator() {
    inputRef.current.style.border = '1px solid #126133';
  }

  function renderInput() {
    return (
      <div className="d-flex flex-row align-items-center position-relative">
        <input
          type={displayViewPassword || displayUrlInput ? typeState : type}
          ref={inputRef}
          value={getInputValue()}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={`${inputClassName} ${getErrorText() && 'error-input'}`}
          disabled={disabled}
          id={id}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          placeholder={placeholder}
          {...rest}
        />
        {withTooltip && (
          <Tooltip
            // className="toggle-status-button"
            placement="top"
            target={id}
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
          >
            {tooltipText}
          </Tooltip>
        )}
        {/* <input
          type={displayViewPassword ? typeState : type}
          ref={inputRef}
          value={getInputValue()}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={`${inputClassName} ${getErrorText() && 'error-input'}`}
          disabled={disabled}
          id={id}
          onFocus={onFocus}
          {...rest}
        /> */}
        {displayVerifyButton && (
          <button
            className="btn btn-verify m-0"
            type="button"
            id="button-addon2"
            onClick={toVerifyButtonClick}
            disabled={disabledVerifyButton}
          >
            Verify
          </button>
        )}
        {displayViewPassword && (
          <a
            className=""
            type="button"
            id="button-addon2"
            style={
              isDesktop
                ? { marginLeft: '-35px', paddingTop: '10px' }
                : { marginLeft: '-35px', alignSelf: 'center' }
            }
            onClick={() => {
              if (typeState === 'password') {
                setTypeState('text');
              } else {
                setTypeState('password');
              }
            }}
          >
            <img
              src={typeState === 'password' ? EyeClosed : EyeOpen}
              alt="Eye"
            />
          </a>
        )}
        <div
          className="inputautocomplete-list"
          style={{ top: autoCompleteListTop }}
        >
          {
            <>
              {autoCompleteLoading && (
                <div>
                  <LoadingOnText width="20" height="20" />
                </div>
              )}
              {isAutoComplete &&
                isFocus &&
                stateVal.trim() !== '' &&
                !autoCompleteLoading &&
                (autoCompleteData.length > 0 ? (
                  autoCompleteData.map((d) => (
                    <div
                      onClick={() => {
                        autoCompleteDataSelected(d);
                      }}
                      key={d[autoCompleteKeyField]}
                    >
                      <text>{d[autoCompleteLabelField]}</text>
                    </div>
                  ))
                ) : (
                  <div className="txt-no-data">
                    <text>No result</text>
                  </div>
                ))}
            </>
          }
        </div>
      </div>
    );
  }

  if (wrapped) {
    return isHorizontalInput ? (
      <div
        className={`${className} ${
          isAutoComplete ? 'autocomplete col-12' : ''
        }`}
        style={style}
      >
        <Row>
          <Col className="col-md-2 col-12 align-self-center">
            {title && (
              <label className="label-input-horizontal" htmlFor={id}>
                {title}
              </label>
            )}
            {isMandatoryLabel && (
              <label style={{ marginLeft: '5px', color: '#FF5F5F' }}>*</label>
            )}
          </Col>
          <Col className="col-md-10">
            {renderInput()}
            {getErrorText() && (
              <p className="text-danger text-error mb-0">{getErrorText()}</p>
            )}
            {getStatusText() && (
              <div>
                <p className="text-danger text-status flex-1">
                  {getStatusText()}
                </p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    ) : (
      <div
        className={`${className} ${
          isAutoComplete ? 'autocomplete col-12' : ''
        }`}
        style={style}
      >
        {title && <label htmlFor={id}>{title}</label>}
        {isMandatoryLabel && (
          <label style={{ marginLeft: '5px', color: '#FF5F5F' }}>*</label>
        )}
        {renderInput()}
        {getErrorText() && (
          <p className="text-danger text-error mb-0">{getErrorText()}</p>
        )}
        {getStatusText() && (
          <div d-flex flex-column>
            <p className="text-danger text-status flex-1">{getStatusText()}</p>
          </div>
        )}
      </div>
    );
  }

  if (isAutoComplete) {
    return <div className="form-group autocomplete">{renderInput()}</div>;
  }
  return renderInput();
}

InputText.defaultProps = {
  title: '',
  id: '',
  type: 'text',
  inputClassName: 'form-control',
  value: '',
  onValueChange: () => {},
  className: '',
  style: {},
  wrapped: true,
  disabled: false,
  errorText: '',
  onKeyDown: () => {},
  isAutoComplete: false,
  autoCompleteData: [],
  autoCompleteLabelField: 'name',
  autoCompleteLoading: false,
  autoCompleteListTop: '75px',
  autoCompleteDataSelected: () => {},
  autoCompleteDataCleanUp: () => {},
  maxLength: Infinity,
  minLength: -Infinity,
  autocomplateOnAfterTyped: () => {},
  autoCompleteKeyField: 'id',
  usePropErrorRes: false,
  displayViewPassword: false,
  displayVerifyButton: false,
  filterErrorText: null,
  onVerifyButtonClick: () => {},
  statusVerify: () => {
    0;
  },
  disabledVerifyButton: false,
  displayUrlInput: false,
  withTooltip: false,
  tooltipText: 'your text',
  displayResetPassword: false,
  textBtnReset: 'Reset Password',
  positiveResetText: 'Password berhasil diganti',
  onResetPasswordClick: () => {},
  statusReset: () => {},
  placeholder: '',
  isMandatoryLabel: false,
  maxLengthCustomWarning: null,
  minLengthCustomWarning: null,
  numericCustomWarning: null,
  isDangerIndicator: false,
  isWarningIndicator: false,
  onBlur: () => {},
  isHorizontalInput: false,
};

InputText.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  inputClassName: PropTypes.string,
  wrapped: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  onKeyDown: PropTypes.func,
  isAutoComplete: PropTypes.bool,
  autoCompleteData: PropTypes.arrayOf(Object),
  autoCompleteLabelField: PropTypes.string,
  autoCompleteDataSelected: PropTypes.func,
  autoCompleteDataCleanUp: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  autocomplateOnAfterTyped: PropTypes.func,
  autoCompleteKeyField: PropTypes.string,
  usePropErrorRes: PropTypes.bool,
  displayViewPassword: PropTypes.bool,
  displayVerifyButton: PropTypes.bool,
  autoCompleteLoading: PropTypes.bool,
  autoCompleteListTop: PropTypes.string,
  filterErrorText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onVerifyButtonClick: PropTypes.func,
  statusVerify: PropTypes.func,
  disabledVerifyButton: PropTypes.bool,
  displayUrlInput: PropTypes.bool,
  withTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  displayResetPassword: PropTypes.bool,
  textBtnReset: PropTypes.string,
  positiveResetText: PropTypes.string,
  onResetPasswordClick: PropTypes.func,
  statusReset: PropTypes.func,
  placeholder: PropTypes.string,
  isMandatoryLabel: PropTypes.bool,
  maxLengthCustomWarning: PropTypes.func,
  minLengthCustomWarning: PropTypes.func,
  numericCustomWarning: PropTypes.func,
  isDangerIndicator: PropTypes.bool,
  isWarningIndicator: PropTypes.bool,
  onBlur: PropTypes.func,
  isHorizontalInput: PropTypes.bool,
};
