import { mockGo } from '..';
import {
  getUserUpgradeVoltaPlusResponse,
  savePicPickupBatteryResponse,
} from './mock-responses';

var getListUserUpgradeVoltaPlusMock = (data = null) => {
  return mockGo
    .onGet('/upgrade-volta-plus/user', data)
    .reply(200, getUserUpgradeVoltaPlusResponse());
};

var savePicPickupBatteryMock = (data = null) => {
  return mockGo
    .onPost('/upgrade-volta-plus/save-pickup-battery-pic', data)
    .reply(200, savePicPickupBatteryResponse(data));
};

var saveDowngradeUserBundleMock = (data = null) => {
  return mockGo
    .onPost('/upgrade-volta-plus/save-downgrade-user-bundle', data)
    .reply(200, savePicPickupBatteryResponse(data));
};

const startMockUpgradeVoltaPlusServicesApi = () => {
  getListUserUpgradeVoltaPlusMock();
};

export {
  getListUserUpgradeVoltaPlusMock,
  savePicPickupBatteryMock,
  saveDowngradeUserBundleMock,
};

export default startMockUpgradeVoltaPlusServicesApi;
