import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import swal from 'sweetalert';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Image, VoltaNavLink } from '@volta-component-card';
import { Loading } from '@volta-component-svg';
import {
  ChecklistGreen,
  ConstructionGreen,
  ConstructionWhite,
  logout as logoutSvg,
  TestingGreen,
  TestingWhite,
  ChevronDown,
} from '@volta-svg';
import { logOut, setSession } from '@volta-module-auth/actions';
import { LIST_SIDEBAR, LIST_SIDEBAR_FOR_ROLE_DEALER } from './data';
import { useWindowSize } from '@volta-utils/hooks';
import './styles.scss';
import {
  setHeaderName,
  setOpenMenu,
} from '@volta/component/layouts/Header/headerActions';
import SidebarItem from './SidebarItem';

const HIDDEN_POSITION = -300;

function Sidebar({ menuIsOpen, setOpenMenu, stateHeader, setHeaderName }) {
  const [windowSize, windowHeight] = useWindowSize();
  const dispatch = useDispatch();
  const [loadingLogout, setLoadingLogout] = useState(false);
  const user = useSelector((state) => state.auth.session.user);

  const isDealerAdmin = useMemo(() => {
    const userGroupList = [1, 10, 11, 2];
    if (user) {
      if (userGroupList.indexOf(user.user_group_id) !== -1) {
        return true;
      }
    }
    return false;
  }, []);

  function doLogOut() {
    if (loadingLogout) {
      return;
    }
    swal({
      title: 'Apa anda yakin ingin logout?',
      text: 'Anda akan diarahkan ke halaman login',
      icon: 'warning',
      buttons: true,
    }).then((yes) => {
      if (yes) {
        setLoadingLogout(true);
        setTimeout(() => {
          setLoadingLogout(false);
          dispatch(
            setSession({
              key: 'user',
              data: null,
            })
          );
          dispatch(logOut());
        }, 1000);
      }
    });
  }

  function checkValidSidebar(item) {
    if (item.value === 'Service' && !isDealerAdmin) {
      return false;
    }
    return true;
  }

  const sidebarPositionStyle =
    windowSize > 1024
      ? {
          left: menuIsOpen ? 0 : HIDDEN_POSITION,
        }
      : {
          right: menuIsOpen ? 0 : HIDDEN_POSITION,
        };

  const listSidebar = useMemo(() => {
    switch (user?.role_user_id) {
      case parseInt(process.env.REACT_APP_DEALER_ROLE_ID):
        return LIST_SIDEBAR_FOR_ROLE_DEALER;
      default:
        return LIST_SIDEBAR;
    }
  }, [user]);

  return (
    <>
      <Col style={sidebarPositionStyle} className="sidebar">
        <div className="menu">
          {listSidebar.map(
            (item) =>
              checkValidSidebar(item) && (
                <SidebarItem
                  key={item.id}
                  item={item}
                  setOpenMenu={setOpenMenu}
                  stateHeader={stateHeader}
                  setHeaderName={setHeaderName}
                  isDealerAdmin={isDealerAdmin}
                />
              )
          )}
        </div>
        <a
          onClick={doLogOut}
          className="logout d-flex flex-row align-items-center mt-4 justify-content-center"
        >
          {!loadingLogout ? (
            <>
              <img src={logoutSvg} className="me-4" />
              <p className="m-0">Log out</p>
            </>
          ) : (
            <Loading height={100} />
          )}
        </a>
      </Col>
    </>
  );
}

const mapStateToProps = (state) => ({
  menuIsOpen: state.header.menuIsOpen,
  stateHeader: state.header,
});

const mapDispatchToProps = {
  setOpenMenu: (payload) => setOpenMenu(payload),
  setHeaderName: (payload) => setHeaderName(payload),
};

Sidebar.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
