import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const IMAGE_NOT_FOUND_SRC =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd8LWlb8l34MXvr3BonwEYsd11lw1QKQVEiQ&usqp=CAU';

export default function Image({ src, alt, errImage, ...props }) {
  const [isError, setError] = useState(false);

  useEffect(() => {
    if (errImage) {
      setError(false);
    }
  }, [src]);

  function onError() {
    setError(true);
  }

  return (
    <div className="image">
      <img
        onError={onError}
        src={!isError ? src : errImage}
        alt={alt}
        {...props}
      />
    </div>
  );
}

Image.defaultProps = {
  src: '',
  alt: '',
  errImage: IMAGE_NOT_FOUND_SRC,
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  errImage: PropTypes.string,
};
