import React from 'react';
import { useSelector } from 'react-redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '@volta-redux/store';
import { MainNavigation, AuthNavigation } from '@volta-navigation';
import { Providers as ContextProviders } from './context';
import startAllMockServicesApi from './api/mock-services';

const { VoltaAlertProvider, VoltaDialogProvider } = ContextProviders;
function Navigation() {
  const currentNavigation = useSelector(
    (state) => state.auth.currentNavigation
  );

  function renderNavigation() {
    if (currentNavigation === 'auth') {
      return <AuthNavigation />;
    } else {
      return <MainNavigation />;
    }
  }

  return renderNavigation();
}

function App() {
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    startAllMockServicesApi();
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <VoltaDialogProvider>
          <VoltaAlertProvider>
            <Navigation />
          </VoltaAlertProvider>
        </VoltaDialogProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
