import React from 'react';
import PropTypes from 'prop-types';

export default function EllipseStatus({ status, className }) {
  const [floodColor, setFloodColor] = React.useState('#189b32');
  const [fillColor, setFillColor] = React.useState('#19a134');
  React.useEffect(() => {
    switch (status) {
      case 'Deactive':
        setFloodColor('red');
        setFillColor('red');
        break;
      case 'Active':
        setFloodColor('#189b32');
        setFillColor('#19a134');
        break;
      default:
    }
  }, [status]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="20"
      height="20"
      viewBox="0 0 30 30"
    >
      <defs>
        <filter
          id="Ellipse_484"
          x="0"
          y="0"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodColor={floodColor} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_484)">
        <circle
          id="Ellipse_484-2"
          data-name="Ellipse 484"
          cx="6"
          cy="6"
          r="6"
          transform="translate(9 9)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}

EllipseStatus.defaultProps = {
  status: 'Active',
  className: '',
};

EllipseStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};
