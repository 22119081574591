/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Input } from 'reactstrap';
import { VoltaModal } from '@volta-component-layout';
import { Search, Filter, Plus, Trash, Download } from '@volta-svg';
import { Loading } from '@volta-component-svg';
import { InputPagination } from '@volta-component-input';
import {
  useDebounce,
  usePrevious,
  useLimitTableData,
  useWindowSize,
  useDebounceAlt,
} from '@volta-utils/hooks';
import './styles.scss';
import FilterModal from '../FilterModal';

/**
 * --- To Using Searching Text Filter with Debounce Searching Method---
 * set Props isDebounceSearch True
 * set Props onAfterTextTyped to make your action after debounce
 */

/**
 * --- To using Searching Text Filter with keypress Method
 * set Props isDebounceSearch false (default is false) , you can skip this props
 * set Props onTextKeyDownTyping
 */
export default function TableContent({
  columns,
  data,
  renderFilterContent,
  addButton,
  addButtonText,
  renderModalBody,
  onModalButtonClick,
  paginationSize,
  onPaginationChange,
  totalSize,
  modalTitle,
  modalUnmountOnClose,
  inputPlaceholder,
  defaultSubmitButton,
  onAddButtonClicked,
  showModal,
  keyField,
  loading,
  onApplyFilterClicked,
  showApplyFilterButton,
  applyButtonTitle,
  renderFilterDefault,
  limit: limitProps,
  onAfterTextTyped,
  onTextTyping,
  onTextKeyDownTyping,
  page: pageProps,
  textSearch: textSearchProps,
  onResetFilterClicked,
  useSystemParameterLimit,
  displayPagination,
  textSearchVisibility,
  filterButtonVisibility,
  isDebounceSearch,
  showResetSearchButton,
  onResetSearchClicked,
  variant,
  actionButtons,
}) {
  const [isShowFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(pageProps - 1);
  const [currentPage, setCurrentPage] = useState(pageProps);
  const [textSearch, setTextSearch] = useState(textSearchProps);
  const prevTextSearchRef = useRef();
  const prevPageProps = usePrevious(pageProps);
  const [limit, pLoading] = useLimitTableData(
    useSystemParameterLimit,
    limitProps
  );
  const [windowSize] = useWindowSize();
  const isDesktop = windowSize > 1024;

  useEffect(() => {
    const p = document.querySelector('.react-bootstrap-table-pagination');
    const p2 = document.querySelector('.react-bootstrap-table-pagination-list');

    if (p) {
      p.children.length !== 0 && p.children[0].remove();
      p.className += ' d-flex justify-content-center align-items-center';
      if (p2) {
        p2.className += ' d-flex justify-content-center';
      }
    }
  }, []);

  useEffect(() => {
    if (prevPageProps !== undefined && pageProps !== prevPageProps) {
      setPage(pageProps - 1);
      if (pageProps === 1) {
        setCurrentPage(pageProps);
      }
    }
  }, [pageProps, prevPageProps]);

  const startDebounce = useDebounce(afterDebounce);
  const deb = useDebounceAlt(textSearch, 500);

  // debounce text search
  useEffect(() => {
    setTextSearch(textSearchProps);
  }, [textSearchProps]);

  // debounce text search
  useEffect(() => {
    if (textSearch !== prevTextSearchRef.current) {
      onAfterTextTyped(textSearch);
    }
    prevTextSearchRef.current = textSearch;
  }, [deb]);

  function afterDebounce(e) {
    onAfterTextTyped(e);
  }

  function afterTextTypingEvent(e) {
    startDebounce(e);
    setTextSearch(e.target.value);
  }

  function onTextKeyDown(e) {
    onTextKeyDownTyping(e);
    afterTextTypingEvent(e);
  }

  function onChangeText(e) {
    setTextSearch(e.target.value);
    onTextTyping(e);
  }

  function toggle() {
    setModal(!modal);
  }

  function setCurrentPagination(i, { totalPagination, endNumber }, flag = '') {
    setPage(i);
    onPaginationChange(i);
    // console.log({totalPagination, page, paginationSize});
    if (flag === 'last') {
      setCurrentPage(totalPagination - endNumber + 1);
      return;
    } else if (flag === 'first') {
      setCurrentPage(1);
      return;
    }
    const l = endNumber + currentPage;
    // console.log({l});
    if (i === l - 1) {
      setCurrentPage(currentPage + 1);
    } else if (i < currentPage && i !== 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  function renderPagination() {
    if (displayPagination) {
      return (
        <InputPagination
          data={data}
          limit={limit}
          page={page}
          paginationSize={paginationSize}
          currentPage={currentPage}
          setCurrentPagination={setCurrentPagination}
          totalSize={totalSize}
        />
      );
    }
    return null;
  }

  function renderFilterButton() {
    return (
      <button
        onClick={() => setShowFilter(!isShowFilter)}
        className="btn filter-button"
      >
        <img src={Filter} />
      </button>
    );
  }

  function renderAddButton() {
    return (
      <button
        onClick={() =>
          typeof onAddButtonClicked === 'function'
            ? onAddButtonClicked()
            : setModal(true)
        }
        className={`btn add-button${
          variant === 'secondary' ? ' secondary' : ''
        }`}
      >
        {variant === 'primary' && <img className="plus" src={Plus} />}
        <span>{addButtonText}</span>
      </button>
    );
  }

  function renderFilterContentDefault(context) {
    if (context.toggle) {
      context.onClosePress = context.toggle;
    }
    return (
      <div className="d-flex flex-column filter-content justify-content-between">
        {renderFilterContent(context)}
        {showApplyFilterButton && isDesktop && (
          <div className="submit-content mt-4">
            <button
              onClick={() =>
                typeof onResetFilterClicked === 'function'
                  ? onResetFilterClicked(context)
                  : {}
              }
              className="d-flex btn btn-reset align-items-center justify-content-center"
            >
              <p>Reset</p>
            </button>
            <button
              onClick={() =>
                typeof onApplyFilterClicked === 'function'
                  ? onApplyFilterClicked(context)
                  : {}
              }
              className="d-flex btn btn-submit align-items-center justify-content-center"
            >
              <p>{applyButtonTitle}</p>
            </button>
            {/* </Col>
            </Row> */}
          </div>
        )}
        {showApplyFilterButton && !isDesktop && (
          <div className="d-flex flex-column submit-content mt-4">
            <button
              onClick={() =>
                typeof onApplyFilterClicked === 'function'
                  ? onApplyFilterClicked(context)
                  : {}
              }
              className="d-flex btn btn-submit align-items-center justify-content-center"
            >
              <p>{applyButtonTitle}</p>
            </button>
            <button
              onClick={() =>
                typeof onResetFilterClicked === 'function'
                  ? onResetFilterClicked(context)
                  : {}
              }
              className="d-flex btn btn-reset align-items-center justify-content-center mt-2"
            >
              <p>Reset</p>
            </button>
            {/* </Col>
            </Row> */}
          </div>
        )}
      </div>
    );
  }

  function renderFilter(context) {
    if (renderFilterDefault) {
      return renderFilterContentDefault(context);
    }
    return renderFilterContent(context);
  }

  function renderSearchForm() {
    return (
      <>
        <div className="search-form-group me-2">
          <img src={Search} />
          <Input
            value={textSearch}
            onChange={onChangeText}
            {...(!isDebounceSearch && { onKeyDown: onTextKeyDown })}
            className="cari-textinput"
            placeholder={inputPlaceholder}
          />
        </div>
        {showResetSearchButton && (
          <button
            onClick={() => {
              setTextSearch('');
              onResetSearchClicked();
            }}
            className="btn filter-button me-2"
            disabled={loading}
          >
            <img src={Trash} />
          </button>
        )}
      </>
    );
  }

  const emptyDataMessage = () => {
    return 'No Data';
  };

  // if (loading || pLoading) {
  //   return (
  //     <div className="flex-1">
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <div className={`table-content ${variant}`}>
      {textSearchVisibility && (
        <div
          className={`d-flex heading align-items-center justify-content-between ${
            variant === 'secondary' ? 'flex-row-reverse' : 'flex-row'
          }`}
        >
          {isDesktop && (
            <div className="d-flex gap-2">
              {addButton && renderAddButton()}
              {actionButtons.map((actionButton) => actionButton)}
            </div>
          )}
          <div className="search-and-filter">
            {renderSearchForm()}
            {filterButtonVisibility && renderFilterButton()}
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className="d-flex flex-column justify-content-end m mt-4 gap-3">
          {addButton && renderAddButton()}
          <div className="d-flex gap-3">
            {actionButtons.map((actionButton) => actionButton)}
          </div>
        </div>
      )}
      <div className="mt-4">
        {loading && <Loading />}
        {pLoading && <Loading />}
        {!loading && !pLoading && (
          <BootstrapTable
            bootstrap4
            bordered={false}
            wrapperClasses="volta-table"
            rowClasses="table-row"
            keyField={keyField}
            columns={columns}
            data={data}
            headerClasses="table-heading"
            noDataIndication={emptyDataMessage}
          />
        )}
        {renderPagination()}
      </div>
      <FilterModal
        isOpen={isShowFilter}
        toggle={() => setShowFilter(false)}
        onClosePress={() => setShowFilter(false)}
        renderFilterContent={renderFilter}
      />
      {showModal && (
        <VoltaModal
          onModalButtonClick={() => onModalButtonClick({ modal, setModal })}
          renderBody={renderModalBody}
          isOpen={modal}
          toggle={toggle}
          title={modalTitle}
          customClass="overflow"
          defaultSubmitButton={defaultSubmitButton}
          unmountOnClose={modalUnmountOnClose}
        />
      )}
    </div>
  );
}

TableContent.defaultProps = {
  columns: [],
  data: [],
  renderFilterContent: () => null,
  renderFilterDefault: true,
  onApplyFilterClicked: () => {},
  right: -51,
  addButton: false,
  addButtonText: '',
  renderModalBody: () => null,
  onModalButtonClick: () => {},
  paginationSize: 5,
  onPaginationChange: () => {},
  totalSize: -1,
  modalTitle: '',
  modalUnmountOnClose: false,
  addXLS: false,
  inputPlaceholder: 'ID Pengguna',
  defaultSubmitButton: false,
  onAddButtonClicked: null,
  showModal: true,
  keyField: 'no',
  loading: false,
  showApplyFilterButton: false,
  applyButtonTitle: 'Terapkan Filter',
  limit: 8,
  onAfterTextTyped: () => {},
  onTextTyping: () => {},
  page: 1,
  onTextKeyDownTyping: () => {},
  textSearch: '',
  onResetFilterClicked: () => {},
  useSystemParameterLimit: true,
  displayPagination: true,
  textSearchVisibility: true,
  filterButtonVisibility: true,
  isDebounceSearch: false,
  showResetSearchButton: false,
  onResetSearchClicked: () => {},
  variant: 'primary',
  actionButtons: [],
};

TableContent.propTypes = {
  columns: PropTypes.arrayOf(Object),
  data: PropTypes.arrayOf(Object),
  renderFilterContent: PropTypes.func,
  renderFilterDefault: PropTypes.bool,
  addButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  renderModalBody: PropTypes.func,
  onModalButtonClick: PropTypes.func,
  paginationSize: PropTypes.number,
  onPaginationChange: PropTypes.func,
  totalSize: PropTypes.number,
  modalTitle: PropTypes.string,
  modalUnmountOnClose: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  defaultSubmitButton: PropTypes.bool,
  onAddButtonClicked: PropTypes.func,
  showModal: PropTypes.bool,
  keyField: PropTypes.string,
  loading: PropTypes.bool,
  onApplyFilterClicked: PropTypes.func,
  showApplyFilterButton: PropTypes.bool,
  applyButtonTitle: PropTypes.string,
  limit: PropTypes.number,
  onAfterTextTyped: PropTypes.func,
  onTextTyping: PropTypes.func,
  page: PropTypes.number,
  onTextKeyDownTyping: PropTypes.func,
  textSearch: PropTypes.string,
  onResetFilterClicked: PropTypes.func,
  useSystemParameterLimit: PropTypes.bool,
  displayPagination: PropTypes.bool,
  filterButtonVisibility: PropTypes.bool,
  isDebounceSearch: PropTypes.bool,
  showResetSearchButton: PropTypes.bool,
  onResetSearchClicked: PropTypes.func,
  textSearchVisibility: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  actionButtons: PropTypes.arrayOf(PropTypes.node),
};
