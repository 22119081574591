import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';

export default function InputCheckboxV2({
  isChecked,
  onSelectedChange,
  label,
}) {
  const id = `input-checkbox-${label.replace(' ', '-').toLowerCase()}`;
  return (
    <div>
      <Input
        type="checkbox"
        name={id}
        id={id}
        checked={isChecked}
        onChange={() => {
          onSelectedChange(!isChecked);
        }}
      />
      <Label htmlFor={id} className="ms-2 text-gray-900">
        {label}
      </Label>
    </div>
  );
}

InputCheckboxV2.defaultProps = {
  isChecked: false,
  onSelectedChange: () => {},
  label: 'label',
};

InputCheckboxV2.propTypes = {
  isChecked: PropTypes.bool,
  onSelectedChange: PropTypes.func,
  label: PropTypes.string,
};
