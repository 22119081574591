import axios from 'axios';

const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
const / = process.env.REACT_APP_/;
const /_GO = process.env.REACT_APP_/_GO;
const /_NODE_STB = process.env.REACT_APP_/_NODE_STB;
const /_GO_STB = process.env.REACT_APP_/_GO_STB;
const /_GO_STB_SEMOLIS = process.env.REACT_APP_/_GO_STB_SEMOLIS;
const api = axios.create({
  baseURL: /,
});

const apiGo = axios.create({
  baseURL: /_GO,
});

const apiNodeStb = axios.create({
  baseURL: /_NODE_STB,
});

const apiGoStb = axios.create({
  baseURL: /_GO_STB,
});

const apiGoStbSemolis = axios.create({
  baseURL: /_GO_STB_SEMOLIS,
});

const apiNodeMock = axios.create({
  baseURL: /,
});

const apiGoMock = axios.create({
  baseURL: /_GO,
});

if (isDev) {
  api.interceptors.request.use(
    function (config) {
      console.log('Request ==> ', config);
      return config;
    },
    function (error) {
      console.log('Request error ==>', error);
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      console.log('Response Node ==>', response);
      return response;
    },
    function (error) {
      console.log('Response error Node ==>', error);
      return Promise.reject(error);
    }
  );

  apiNodeMock.interceptors.request.use(
    function (config) {
      console.log('Request Node Mock ==> ', config);
      return config;
    },
    function (error) {
      console.log('Request Node Mock error ==>', error);
      return Promise.reject(error);
    }
  );

  apiNodeMock.interceptors.response.use(
    function (response) {
      console.log('Response Node Mock ==>', response);
      return response;
    },
    function (error) {
      console.log('Response error Node Mock ==>', error);
      return Promise.reject(error);
    }
  );

  apiGo.interceptors.response.use(
    function (response) {
      console.log('Response Go ==>', response);
      return response;
    },
    function (error) {
      console.log('Response error Go ==>', error);
      return Promise.reject(error);
    }
  );

  apiGoMock.interceptors.request.use(
    function (config) {
      console.log('Request GO Mock ==> ', config);
      return config;
    },
    function (error) {
      console.log('Request GO Mock error ==>', error);
      return Promise.reject(error);
    }
  );

  apiGoMock.interceptors.response.use(
    function (response) {
      console.log('Response Go Mock ==>', response);
      return response;
    },
    function (error) {
      console.log('Response error Go Mock ==>', error);
      return Promise.reject(error);
    }
  );

  apiNodeStb.interceptors.request.use(
    function (config) {
      console.log('Request Node STB ==> ', config);
      return config;
    },
    function (error) {
      console.log('Request error Node STB ==>', error);
      return Promise.reject(error);
    }
  );

  apiGoStb.interceptors.response.use(
    function (response) {
      console.log('Response Go STB ==>', response);
      return response;
    },
    function (error) {
      console.log('Response error Go STB ==>', error.toJSON());
      console.log('Response error Go STB ==>', error.response);
      return Promise.reject(error);
    }
  );

  apiGoStbSemolis.interceptors.response.use(
    function (response) {
      console.log('Response Go STB Semolis ==>', response);
      return response;
    },
    function (error) {
      console.log('error Go STB Semolis ==>', error.toJSON());
      console.log('Response error Go STB Semolis ==>', error.response);
      return Promise.reject(error.response);
    }
  );
}

export {
  apiGo,
  /,
  /_GO,
  apiNodeStb,
  apiGoStb,
  /_GO_STB,
  apiGoStbSemolis,
  /_GO_STB_SEMOLIS,
  apiNodeMock,
  apiGoMock,
};

export default api;
