var generalNotificationResponse = (filter) => {
  let filterKey = [];
  let filterValue = '';
  if (filter?.or?.length > 0) {
    for (let filt of filter?.or) {
      filterKey = [...filterKey, Object.keys(filt.generalNotifications)[0]];
    }
    filterValue = filter?.or[0]?.generalNotifications.title.likeInsensitive;
  }

  const filterGeneralNotifications = {
    generalNotifications: { ...generalNotifications.generalNotifications },
  };
  const filteredGeneralNotifications =
    generalNotifications.generalNotifications.nodes.filter((value) => {
      return value['title'].toLowerCase().includes(filterValue.toLowerCase());
    });
  filterGeneralNotifications.generalNotifications.nodes =
    filteredGeneralNotifications;
  return filterValue === ''
    ? { data: generalNotifications }
    : { data: filterGeneralNotifications };
};

var gnrNotificationDetailResponse = (id = 1, filter) => {
  switch (id) {
    case 1: {
      let filterKey = [];
      let filterValue = '';
      if (filter?.or?.length > 0) {
        for (let filt of filter?.or) {
          filterKey = [...filterKey, Object.keys(filt.userNotifications)[0]];
        }
        filterValue = filter?.or[0]?.userNotifications.name.likeInsensitive;
      }

      const filterUserNotifications = {
        userNotifications: { ...userNotificationOne.userNotifications },
      };
      const filteredUserNotifications =
        userNotificationOne.userNotifications.nodes.filter((value) => {
          return value['name']
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        });
      filterUserNotifications.userNotifications.nodes =
        filteredUserNotifications;
      return filterValue === ''
        ? { data: userNotificationOne }
        : { data: filterUserNotifications };
    }
    case 2: {
      let filterKey = [];
      let filterValue = '';
      if (filter?.or?.length > 0) {
        for (let filt of filter?.or) {
          filterKey = [...filterKey, Object.keys(filt.userNotifications)[0]];
        }
        filterValue = filter?.or[0]?.userNotifications.name.likeInsensitive;
      }

      const filterUserNotifications = {
        userNotifications: { ...userNotificationTwo.userNotifications },
      };
      const filteredUserNotifications =
        userNotificationTwo.userNotifications.nodes.filter((value) => {
          return value['name']
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        });
      filterUserNotifications.userNotifications.nodes =
        filteredUserNotifications;
      return filterValue === ''
        ? { data: userNotificationTwo }
        : { data: filterUserNotifications };
    }
    default:
      return {
        data: userNotificationOne,
      };
  }
};

var createNotificationResponse = (data) => {
  return {
    data: data,
    meta: metaSuccess,
  };
};

var generalNotifications = {
  generalNotifications: {
    nodes: [
      {
        id: 1,
        no: '1',
        title: 'TAHUN BARU TELAH TIBA',
        message: 'yuk beli motor volta',
        name: 'Semua pengguna',
        createdBy: 'Nugroho',
        createdDate: '2022-09-30T18:03:29.672556',
      },
      {
        id: 2,
        no: '2',
        title: 'TAHUN BARU AKAN TIBA',
        message: 'mari beli motor volta',
        name: 'Semua pengguna',
        createdBy: 'Nugroho',
        createdDate: '2022-09-28T21:42:43.215652',
      },
    ],
    totalCount: 2,
  },
};

var userNotificationOne = {
  userNotifications: {
    nodes: [
      {
        id: 1,
        name: 'Arief fleet 2',
        isRead: false,
        createdDate: '2022-09-30T18:03:29.672556',
        readDate: null,
      },
      {
        id: 2,
        name: 'Annisa Bayyina',
        isRead: false,
        createdDate: '2022-09-30T18:03:29.672556',
        readDate: null,
      },
    ],
    totalCount: 1,
  },
};

var userNotificationTwo = {
  userNotifications: {
    nodes: [
      {
        id: 1,
        name: 'Arief fleet 2',
        isRead: true,
        createdDate: '2022-09-28T21:42:43.215652',
        readDate: '2022-09-30T18:02:55.680662',
      },
      {
        id: 2,
        name: 'Annisa Bayyina',
        isRead: false,
        createdDate: '2022-09-28T21:42:43.215652',
        readDate: null,
      },
    ],
    totalCount: 2,
  },
};

var metaSuccess = {
  info: null,
  message: 'Request succesfully proceed',
  success: true,
};

export {
  generalNotificationResponse,
  gnrNotificationDetailResponse,
  createNotificationResponse,
};
