import MockAdapter from 'axios-mock-adapter';
import { apiGoMock, apiNodeMock } from '@volta/redux/api';
import startMockManageUserServicesApi from './manage-user';
import startMockMasterApplicationsServicesApi from './master-applications';
import startMockBroadcastNotificationServicesApi from './broadcast-notification';
import startMockUpgradeVoltaPlusServicesApi from './upgrade-volta-plus';
import startMockMotorcycleBundlesServicesApi from './motorcycle-bundle';

var mock = new MockAdapter(apiNodeMock);
var mockGo = new MockAdapter(apiGoMock);

const startAllMockServicesApi = () => {
  startMockManageUserServicesApi();
  startMockMasterApplicationsServicesApi();
  startMockBroadcastNotificationServicesApi();
  startMockUpgradeVoltaPlusServicesApi();
  startMockMotorcycleBundlesServicesApi();
};

export { mock, mockGo };

export default startAllMockServicesApi;
