import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ChevronLeft, ChevronRight } from '@volta-svg';

export default function InputPagination({
  page,
  totalSize,
  data,
  limit,
  paginationSize,
  setCurrentPagination,
  currentPage,
  className,
}) {
  const totalPagination = useMemo(() => {
    return Math.ceil((totalSize !== -1 ? totalSize : data.length) / limit);
  }, [data]);

  const endNumber = useMemo(() => {
    return totalPagination < paginationSize ? totalPagination : paginationSize;
  }, [totalPagination]);

  function getPaginationListPage() {
    const result = [];
    const end = endNumber;
    for (let i = 0; i < end; i++) {
      result.push(i + (currentPage - 1));
    }
    return result;
  }

  return (
    <div
      className={`row d-flex justify-content-center align-items-center ${className}`}
    >
      <div className="volta-pagination d-flex justify-content-center">
        {page !== 0 && totalPagination > paginationSize && (
          <a
            onClick={() =>
              setCurrentPagination(page - 1, { totalPagination, endNumber })
            }
          >
            <span className="btn-pagination">
              <p className="m-0 d-flex align-items-center">
                <span className="me-2 btn-cursor">
                  <img src={ChevronLeft} />
                </span>
                <span>Prev</span>
              </p>
            </span>
          </a>
        )}
        {page > endNumber - 1 && totalPagination > paginationSize && (
          <>
            <a
              onClick={() =>
                setCurrentPagination(0, { totalPagination, endNumber }, 'first')
              }
            >
              <span className="btn-pagination num">1</span>
            </a>
            <a>
              <span className="btn-pagination num">...</span>
            </a>
          </>
        )}
        {getPaginationListPage().map((i) => (
          <a
            onClick={() =>
              setCurrentPagination(i, { totalPagination, endNumber })
            }
            key={i}
          >
            <span
              className={`btn-pagination num ${i === page ? 'selected' : ''}`}
            >
              {i + 1}
            </span>
          </a>
        ))}
        {page !== totalPagination - 1 && totalPagination > paginationSize && (
          <>
            <a>
              <span className="btn-pagination num">...</span>
            </a>
            <a
              onClick={() =>
                setCurrentPagination(
                  totalPagination - 1,
                  { totalPagination, endNumber },
                  'last'
                )
              }
            >
              <span className="btn-pagination num">{totalPagination}</span>
            </a>
          </>
        )}
        {page !== totalPagination - 1 && totalPagination > paginationSize && (
          <a
            onClick={() =>
              setCurrentPagination(page + 1, { totalPagination, endNumber })
            }
          >
            <span className="btn-pagination">
              <p className="m-0 d-flex align-items-center">
                <span className="me-2">Next</span>
                <span className="btn-cursor">
                  <img src={ChevronRight} />
                </span>
              </p>
            </span>
          </a>
        )}
      </div>
    </div>
  );
}

InputPagination.defaultProps = {
  page: 1,
  totalSize: -1,
  data: [],
  limit: 5,
  paginationSize: 4,
  setCurrentPagination: () => {},
  currentPage: 1,
  className: '',
};

InputPagination.propTypes = {
  page: PropTypes.number,
  totalSize: PropTypes.number,
  data: PropTypes.arrayOf(Object),
  limit: PropTypes.number,
  paginationSize: PropTypes.number,
  setCurrentPagination: PropTypes.func,
  currentPage: PropTypes.number,
  className: PropTypes.string,
};
