import FailedModal from '@volta/component/layouts/FailedModal';
import SuccessModal from '@volta/component/layouts/SuccessModal';
import React, { createContext, useRef, useState } from 'react';

function VoltaAlert() {
  const voltaAlertContext = createContext();

  const VoltaAlertProvider = (props) => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successModalTitle, setSuccessModalTitle] = useState('');
    const [successModalDescription, setSuccessModalDescription] = useState('');
    const [successModalIcon, setSuccessModalIcon] = useState('');
    const [textButtonFailed, setTextButtonFailed] = useState('');
    const [onClosedSuccessAlert, setOnClosedSuccessAlert] = useState(
      () => () => {}
    );

    const [showFailedModal, setShowFailedModal] = useState(false);
    const [failedModalTitle, setFailedModalTitle] = useState('');
    const [failedModalDescription, setFailedModalDescription] = useState('');
    const [failedModalIcon, setFailedModalIcon] = useState('');
    const [textButtonSuccess, setTextButtonSuccess] = useState('');
    const openFailedQueue = useRef([]);

    function openSuccessAlert({
      title,
      description,
      icon,
      textButtonSuccess,
      onClosed = () => {},
    }) {
      setSuccessModalTitle(title);
      setSuccessModalDescription(description);
      setSuccessModalIcon(icon);
      setTextButtonSuccess(textButtonSuccess);
      setOnClosedSuccessAlert(() => onClosed);
      setShowSuccessModal(true);
    }
    function closeSuccessAlert() {
      setShowSuccessModal(false);
    }

    function openFailedAlert({ title, description, icon, textButtonFailed }) {
      if (showFailedModal === false) {
        setFailedModalTitle(title);
        setFailedModalDescription(description);
        setFailedModalIcon(icon);
        setTextButtonFailed(textButtonFailed);
        setShowFailedModal(true);
        return;
      }
      // make openfailedalert queue
      openFailedQueue.current = [
        ...openFailedQueue.current,
        {
          title,
          description,
          icon,
          textButtonFailed,
        },
      ];
    }
    function closeFailedAlert() {
      setShowFailedModal(false);
    }

    function resetSuccessAlert() {
      setSuccessModalTitle('');
      setSuccessModalDescription('');
      setSuccessModalIcon('');
      setOnClosedSuccessAlert(() => () => {});
    }
    function resetFailedAlert() {
      setFailedModalTitle('');
      setFailedModalDescription('');
      setFailedModalIcon('');
    }

    const voltaAlertState = {
      openSuccessAlert,
      openFailedAlert,
    };

    return (
      <voltaAlertContext.Provider value={voltaAlertState}>
        {props.children}
        <FailedModal
          isOpen={showFailedModal}
          toggle={() => {
            setShowFailedModal(!showFailedModal);
          }}
          onClosed={() => {
            if (openFailedQueue.current.length > 0) {
              openFailedAlert(openFailedQueue.current.at(0));
              openFailedQueue.current.shift();
              return;
            }
            resetFailedAlert();
          }}
          onFailedBtnClick={() => {
            closeFailedAlert();
          }}
          title={failedModalTitle}
          description={failedModalDescription}
          withTitle={failedModalTitle ? true : false}
          withDesc={failedModalDescription ? true : false}
          {...(failedModalIcon && { icon: failedModalIcon })}
          {...(textButtonFailed && {
            textButtonFailed: textButtonFailed,
          })}
          unmountOnClose
        />
        <SuccessModal
          isOpen={showSuccessModal}
          toggle={() => {
            setShowSuccessModal(!showSuccessModal);
          }}
          onClosed={() => {
            onClosedSuccessAlert();
            resetSuccessAlert();
          }}
          onSuccessBtnClick={() => {
            closeSuccessAlert();
          }}
          title={successModalTitle}
          description={successModalDescription}
          withTitle={successModalTitle ? true : false}
          withDesc={successModalDescription ? true : false}
          {...(successModalIcon && { icon: successModalIcon })}
          {...(textButtonSuccess && {
            textButtonSuccess: textButtonSuccess,
          })}
          unmountOnClose
        />
      </voltaAlertContext.Provider>
    );
  };

  return {
    VoltaAlertProvider,
    voltaAlertContext,
  };
}

export default VoltaAlert();
