import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Sidebar, Header } from '@volta-component-layout';
import './styles.scss';
import { useWindowSize } from '@volta/utils/hooks';
import { setOpenMenu } from '@volta/component/layouts/Header/headerActions';
import { connect, useSelector } from 'react-redux';
import { Footer } from '@volta/component/layouts/Footer';

const MainAuditTrail = lazy(() =>
  import('@volta-module-audit-trail/screens/Main')
);

const ProfileUsers = lazy(() =>
  import('@volta-module-profile-management/screens/ProfileUsers')
);

const SystemParameter = lazy(() =>
  import('@volta-module-system-parameter/screens/SystemParameterLanding')
);

const DetailProfile = lazy(() =>
  import('@volta-module-profile-management/screens/DetailProfile')
);

const DealerLanding = lazy(() =>
  import('@volta-module-dealer/screens/DealerLanding')
);

const MotorLanding = lazy(() =>
  import('@volta-module-motorcycle/screens/MotorcycleLanding')
);

const ArticleLanding = lazy(() =>
  import('@volta-module-article/screens/ArticleLanding')
);

const ArticleDetail = lazy(() =>
  import('@volta-module-article/screens/ArticleDetail')
);

const BannerLanding = lazy(() =>
  import('@volta-module-banner/screens/BannerLanding')
);

const BannerDetail = lazy(() =>
  import('@volta-module-banner/screens/BannerDetail')
);

const MotorDealerLanding = lazy(() =>
  import('@volta-module-dealer-motor/screens/MotorDealerLanding')
);

const CustomerLanding = lazy(() =>
  import('@volta-module-customer/screens/CustomerLanding')
);

const CustomerAddMotor = lazy(() =>
  import('@volta-module-customer/screens/CustomerAddMotor')
);

const ServiceLanding = lazy(() =>
  import('@volta-module-service/screens/ServiceLanding')
);

const ServiceDetail = lazy(() =>
  import('@volta-module-service/screens/ServiceDetail')
);

const ManualLanding = lazy(() =>
  import('@volta-module-manual/screens/ManualLanding')
);

const ManualRegister = lazy(() =>
  import('@volta-module-manual/screens/ManualRegister')
);

const ManageUserLanding = lazy(() =>
  import('@volta-module-manage-user/screens/ManageUserLanding')
);

const ManageUserRegister = lazy(() =>
  import('@volta-module-manage-user/screens/ManageUserRegister')
);

const MasterApplicationLanding = lazy(() =>
  import('@volta-module-master-application/screens/MasterApplicationLanding')
);

const MasterApplicationRegister = lazy(() =>
  import('@volta-module-master-application/screens/MasterApplicationRegister')
);

const DealerDetail = lazy(() =>
  import('@volta-module-dealer/screens/DealerDetail')
);

const DetailCustomer = lazy(() =>
  import('@volta-module-customer/screens/DetailCustomer')
);

const ChangePassword = lazy(() =>
  import('@volta-module-profile/screens/ChangePassword')
);

const TransactionHistory = lazy(() =>
  import('@volta-module-history/screens/TransactionHistory')
);

const BroadcastNotificationLanding = lazy(() =>
  import(
    '@volta-module-broadcast-notification/screens/BroadcastNotificationLanding'
  )
);

const BroadcastNotificationSend = lazy(() =>
  import(
    '@volta-module-broadcast-notification/screens/BroadcastNotificationSend'
  )
);

const BroadcastNotificationDetail = lazy(() =>
  import(
    '@volta-module-broadcast-notification/screens/BroadcastNotificationDetail'
  )
);

const UpgradeVoltaPlusLanding = lazy(() =>
  import('@volta-module-upgrade-volta-plus/screens/Landing')
);

const DashboardTelkomselLanding = lazy(() =>
  import('@volta-module-dashboard-telkomsel/screens/DashboardTelkomselLanding')
);

const MotorcycleBundleLanding = lazy(() =>
  import('@volta-module-motorcycle-bundle/screens/MotorcycleBundleLanding')
);

const MotorcycleBundleRegister = lazy(() =>
  import('@volta-module-motorcycle-bundle/screens/MotorcycleBundleRegister')
);

const RequestWithdrawLanding = lazy(() =>
  import('@volta-module-request-withdraw/screens/RequestWithdrawLanding')
);

const BatteryLanding = lazy(() =>
  import('@volta-module-battery/screens/BatteryLanding')
);

const SGBMembershipPurchase = lazy(() =>
  import('@volta-module-sgb-membership-purchase/screens/Landing')
);

function Fallback() {
  return (
    <div className="d-flex flex-row justify-content-center">
      <p>Loading...</p>
    </div>
  );
}

function MainNavigation({ setOpenMenu, menuIsOpen }) {
  const user = useSelector((state) => state.auth.session.user);
  const [windowSize] = useWindowSize();

  const isDesktop = windowSize > 1024;

  useEffect(() => {
    setOpenMenu(isDesktop);
  }, [windowSize]);

  const listRoutes = useMemo(() => {
    switch (user?.role_user_id) {
      case parseInt(process.env.REACT_APP_DEALER_ROLE_ID):
        return (
          <Switch>
            <Route exact path="/dealer-motor">
              <MotorDealerLanding />
            </Route>
            <Route exact path="/customer">
              <CustomerLanding />
            </Route>
            <Redirect from="/" to="/dealer-motor" />
          </Switch>
        );
      default:
        return (
          <Switch>
            <Route path="/audit-trail">
              <MainAuditTrail />
            </Route>
            <Route exact path="/manajement-profile">
              <ProfileUsers />
            </Route>
            <Route exact path="/system-parameter">
              <SystemParameter />
            </Route>
            <Route
              path={[
                '/manajement-profile/add-profile',
                '/manajement-profile/detail-profile/:id',
              ]}
            >
              <DetailProfile />
            </Route>
            <Route exact path="/dealer">
              <DealerLanding />
            </Route>
            <Route path={['/dealer/add', '/dealer/detail/:id']}>
              <DealerDetail />
            </Route>
            <Route exact path="/motorcycle">
              <MotorLanding />
            </Route>
            <Route exact path="/article">
              <ArticleLanding />
            </Route>
            <Route path={['/article/detail/:id', '/article/add']}>
              <ArticleDetail />
            </Route>
            <Route exact path="/banner">
              <BannerLanding />
            </Route>
            <Route path={['/banner/detail/:id', '/banner/add']}>
              <BannerDetail />
            </Route>
            <Route exact path="/dealer-motor">
              <MotorDealerLanding />
            </Route>
            <Route exact path="/battery">
              <BatteryLanding />
            </Route>
            <Route exact path="/customer">
              <CustomerLanding />
            </Route>
            <Route exact path={['/customer/add', '/customer/detail/:id']}>
              <DetailCustomer />
            </Route>
            <Route path="/customer/add/motor/:id">
              <CustomerAddMotor />
            </Route>
            <Route exact path="/service">
              <ServiceLanding />
            </Route>
            <Route exact path="/service/detail/:id">
              <ServiceDetail />
            </Route>
            <Route exact path="/manual">
              <ManualLanding />
            </Route>
            <Route path={['/manual/register', '/manual/detail/:id']}>
              <ManualRegister />
            </Route>
            <Route exact path="/manage-user">
              <ManageUserLanding />
            </Route>
            <Route path={['/manage-user/register', '/manage-user/detail/:id']}>
              <ManageUserRegister />
            </Route>
            <Route exact path="/master-application">
              <MasterApplicationLanding />
            </Route>
            <Route
              path={[
                '/master-application/register',
                '/master-application/detail/:id',
              ]}
            >
              <MasterApplicationRegister />
            </Route>
            <Route exact path="/change-password">
              <ChangePassword />
            </Route>
            <Route
              exact
              path={['/transaction-history', '/transaction-history/:flag?']}
            >
              <TransactionHistory />
            </Route>
            <Route exact path="/broadcast-notification">
              <BroadcastNotificationLanding />
            </Route>
            <Route exact path="/broadcast-notification/send">
              <BroadcastNotificationSend />
            </Route>
            <Route exact path="/broadcast-notification/detail/:id">
              <BroadcastNotificationDetail />
            </Route>
            <Route exact path="/upgrade-volta-plus">
              <UpgradeVoltaPlusLanding />
            </Route>
            <Route exact path="/dashboard-telkomsel">
              <DashboardTelkomselLanding />
            </Route>
            <Route exact path="/motorcycle-bundle">
              <MotorcycleBundleLanding />
            </Route>
            <Route
              path={[
                '/motorcycle-bundle/register',
                '/motorcycle-bundle/detail/:id',
              ]}
            >
              <MotorcycleBundleRegister />
            </Route>
            <Route exact path="/request-withdraw">
              <RequestWithdrawLanding />
            </Route>
            <Route exact path="/sgb-membership-purchase">
              <SGBMembershipPurchase />
            </Route>
            <Redirect from="/" to="/audit-trail/masuk-keluar" />
          </Switch>
        );
    }
  }, [user]);

  return (
    <HashRouter>
      <AnimatePresence>
        <Header />
        <Sidebar />
        <div
          className="content"
          style={{
            marginLeft: menuIsOpen && isDesktop ? '260px' : 0,
          }}
        >
          <div className="main-content">
            <Suspense fallback={<Fallback />}>{listRoutes}</Suspense>
          </div>
        </div>
        <Footer />
      </AnimatePresence>
    </HashRouter>
  );
}

const mapStateToProps = (state) => ({
  menuIsOpen: state.header.menuIsOpen,
});
const mapDispatchToProps = {
  setOpenMenu: (payload) => setOpenMenu(payload),
};

MainNavigation.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
