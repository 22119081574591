import {
  ChevronDown,
  ConstructionGreen,
  ConstructionWhite,
  TestingGreen,
  TestingWhite,
} from '@volta/assets/svg';
import { VoltaNavLink, Image } from '@volta/component/cards';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useWindowSize } from '@volta-utils/hooks';
import { useLocation } from 'react-router-dom';

export default function SidebarItem({
  item,
  setOpenMenu,
  stateHeader,
  setHeaderName,
  isDealerAdmin,
}) {
  const location = useLocation();
  const itemGroup = useRef(null);
  const [windowSize, windowHeight] = useWindowSize();
  const [isExpand, setIsExpand] = useState(false);

  function isShown(item) {
    const to = item.to;
    switch (to) {
      case '/dealer-motor':
      case '/customer':
      case '/manual':
      case '/service':
        return isDealerAdmin;
      default:
    }
    return true;
  }

  function isUnderConstruction(item) {
    const to = item.to;
    switch (to) {
      case '/manage-user':
      case '/master-application':
        return true;
      default:
    }
    return false;
  }

  function isTesting(item) {
    const to = item.to;
    switch (to) {
      default:
    }
    return false;
  }

  useEffect(() => {
    const itemGroupEl = itemGroup.current;

    if (itemGroupEl) {
      const hasActiveChild = Array.from(itemGroupEl.children).some((child) =>
        child.classList.contains('active')
      );

      if (hasActiveChild) {
        setIsExpand(true);
      }
    }
  }, [location]);

  return (
    <React.Fragment>
      <div
        className="item"
        onClick={() => setIsExpand((currentValue) => !currentValue)}
      >
        {/* <span className="edge" /> */}
        <div className="d-flex">
          <img className="main-icon" src={item.icon} alt={item.value} />
          <p className="main-title">{item.value}</p>
        </div>
        <img
          className={`expand-icon ${isExpand ? 'is-expanded' : ''}`}
          src={ChevronDown}
          alt="chevron-down"
        />
      </div>
      <div
        className={`item-group ${!isExpand ? 'item-group-hidden' : ''}`}
        ref={itemGroup}
      >
        {item.children.map(
          (child) =>
            isShown(child) &&
            !child.hide && (
              <VoltaNavLink
                key={child.id}
                headerName={child.headerName}
                to={child.to}
                activeClassName="active"
                onClick={() => {
                  setHeaderName(child.headerName);
                  console.log('set open menu -> ', setOpenMenu());
                  console.log('state header -> ', stateHeader);
                  setOpenMenu();
                  if (windowSize <= 1024) {
                    // setLeft(HIDDEN_POSITION);
                    setOpenMenu();
                  }
                }}
                className="item item-child"
              >
                <p className="side-title flex-1">{child.value}</p>
                {isUnderConstruction(child) && (
                  <Image
                    src={
                      stateHeader.tabName === child.headerName
                        ? ConstructionWhite
                        : ConstructionGreen
                    }
                  />
                )}
                {isTesting(child) && (
                  <Image
                    src={
                      stateHeader.tabName === child.headerName
                        ? TestingWhite
                        : TestingGreen
                    }
                  />
                )}
              </VoltaNavLink>
            )
        )}
      </div>
    </React.Fragment>
  );
}
