import { TextDialog, IconDialog } from '@volta/component/layouts/DialogModal';
import React, { createContext, useState } from 'react';

function VoltaDialog() {
  const voltaDialogContext = createContext();
  const dialogOption = { text: 'text', icon: 'icon' };
  const VoltaDialogProvider = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');
    const [textPositiveAnswer, setTextPositiveAnswer] = useState('');
    const [textNegativeAnswer, setTextNegativeAnswer] = useState('');
    const [dialogTitleStyle, setDialogTitleStyle] = useState({});
    const [dialogDescriptionStyle, setDialogDescriptionStyle] = useState({});
    const [dialogType, setDialogType] = useState(dialogOption.text);
    const [onPositiveAnswered, setOnPositiveAnswered] = useState(
      () => () => {}
    );
    const [onNegativeAnswered, setOnNegativeAnswered] = useState(
      () => () => {}
    );
    const [onClosed, setOnClosed] = useState(() => () => {});

    function openDialog({
      title,
      description,
      textPositiveAnswer,
      textNegativeAnswer,
      onPositiveAnswered = () => {},
      onNegativeAnswered = () => {},
      onClosed = () => {},
      titleStyle,
      descriptionStyle,
      type = dialogOption.text,
    }) {
      setDialogTitle(title);
      setDialogDescription(description);
      setTextPositiveAnswer(textPositiveAnswer);
      setTextNegativeAnswer(textNegativeAnswer);
      setDialogTitleStyle(titleStyle);
      setDialogDescriptionStyle(descriptionStyle);
      setOnPositiveAnswered(() => onPositiveAnswered);
      setOnNegativeAnswered(() => onNegativeAnswered);
      setOnClosed(() => onClosed);
      setDialogType(type);
      setShowDialog(true);
    }

    function closeDialog() {
      setShowDialog(false);
    }

    function startLoadingDialog() {
      setIsloading(true);
    }

    function stopLoadingDialog() {
      setIsloading(false);
    }

    function resetDialog() {
      setDialogTitle('');
      setDialogDescription('');
      setTextPositiveAnswer('');
      setTextNegativeAnswer('');
      setDialogTitleStyle({});
      setDialogDescriptionStyle({});
      setOnPositiveAnswered(() => () => {});
      setOnNegativeAnswered(() => () => {});
      setOnClosed(() => () => {});
      setDialogType(dialogOption.text);
      setIsloading(false);
    }

    const voltaDialogState = {
      openDialog,
      closeDialog,
      startLoadingDialog,
      stopLoadingDialog,
      dialogOption,
    };

    return (
      <voltaDialogContext.Provider value={voltaDialogState}>
        {props.children}
        {dialogType === dialogOption.text && (
          <TextDialog
            toggle={() => {
              setShowDialog(!showDialog);
            }}
            isOpen={showDialog}
            isLoading={isLoading}
            withTitle={dialogTitle ? true : false}
            title={dialogTitle}
            withDesc={dialogDescription ? true : false}
            description={dialogDescription}
            {...(textPositiveAnswer && {
              textPositiveAnswer: textPositiveAnswer,
            })}
            {...(textNegativeAnswer && {
              textNegativeAnswer: textNegativeAnswer,
            })}
            onPositiveAnswered={() => {
              onPositiveAnswered();
            }}
            onNegativeAnswered={() => {
              onNegativeAnswered();
            }}
            onClosed={() => {
              onClosed();
              resetDialog();
            }}
            {...(dialogTitleStyle && {
              titleStyle: dialogTitleStyle,
            })}
            {...(dialogDescriptionStyle && {
              descStyle: dialogDescriptionStyle,
            })}
            unmountOnClose
            withCloseBtn={isLoading ? false : true}
          />
        )}
        {dialogType === dialogOption.icon && (
          <IconDialog
            toggle={() => {
              setShowDialog(!showDialog);
            }}
            isOpen={showDialog}
            isLoading={isLoading}
            withTitle={dialogTitle ? true : false}
            title={dialogTitle}
            withDesc={dialogDescription ? true : false}
            description={dialogDescription}
            {...(textPositiveAnswer && {
              textPositiveAnswer: textPositiveAnswer,
            })}
            {...(textNegativeAnswer && {
              textNegativeAnswer: textNegativeAnswer,
            })}
            onPositiveAnswered={() => {
              onPositiveAnswered();
            }}
            onNegativeAnswered={() => {
              onNegativeAnswered();
            }}
            onClosed={() => {
              onClosed();
              resetDialog();
            }}
            {...(dialogTitleStyle && {
              titleStyle: dialogTitleStyle,
            })}
            {...(dialogDescriptionStyle && {
              descStyle: dialogDescriptionStyle,
            })}
            unmountOnClose
            withCloseBtn={isLoading ? false : true}
          />
        )}
      </voltaDialogContext.Provider>
    );
  };
  return {
    VoltaDialogProvider,
    voltaDialogContext,
  };
}

export default VoltaDialog();
