import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFileSelector } from '@volta-utils/hooks';
import './styles.scss';

export default function InputArticle({
  title,
  id,
  className,
  style,
  btnTitle,
  onFileSelected,
  accept,
  errorRes,
  initialSrc,
}) {
  const fileSelector = useFileSelector();
  const [currentFile, setCurrentFile] = useState(null);

  fileSelector.accept = accept;

  fileSelector.onchange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentFile(file);
      typeof onFileSelected === 'function' && onFileSelected(file);
    }
  };

  return (
    <div className={className} style={style}>
      <label htmlFor={id}>{title}</label>
      <div className="d-flex flex-row mt-2 article-content">
        <div className={`col-sm-8 left-content ${errorRes ? 'error' : ''}`}>
          <p className={`${currentFile ? 'text-selected' : 'text-unselected'}`}>
            {currentFile
              ? currentFile.name
              : initialSrc || 'Please Select your file'}
          </p>
        </div>
        <button
          onClick={() => fileSelector.click()}
          className="btn btn-submit-article col-sm-4 d-flex align-items-center justify-content-center"
        >
          {btnTitle}
        </button>
      </div>
      {errorRes && (
        <span className="mt-3 text-danger text-article">{errorRes}</span>
      )}
    </div>
  );
}

InputArticle.defaultProps = {
  title: '',
  id: '',
  onFileSelected: () => {},
  className: '',
  style: {},
  btnTitle: 'Unggah Artikel',
  accept: 'application/pdf',
  errorRes: '',
  initialSrc: '',
};

InputArticle.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  onFileSelected: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  btnTitle: PropTypes.string,
  accept: PropTypes.string,
  errorRes: PropTypes.string,
  initialSrc: PropTypes.string,
};
