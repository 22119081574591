import React from 'react';
import VoltaModal from '../VoltaModal';
import PropTypes from 'prop-types';
import './styles.scss';
import { Image } from '@volta/component/cards';
import { Warning } from '@volta/assets/svg';

export default function FailedModal({
  isOpen,
  toggle,
  onClosed,
  onFailedBtnClick,
  withTitle,
  title,
  description,
  textButtonFailed,
  icon,
  withDesc,
  unmountOnClose,
}) {
  function renderBody() {
    return (
      <div className="body-failed-modal">
        <div className="d-flex mt-4 align-items-center justify-content-center">
          <Image src={icon} />
          <div className="d-flex flex-column align-items-left justify-content-center ms-2">
            {withTitle && <h5 className="title-modal">{title}</h5>}
            {withDesc && <h6 className="desc-modal">{description}</h6>}
          </div>
        </div>
        <div className="d-flex flex-row submit-content">
          <button
            onClick={onFailedBtnClick}
            className="d-flex btn btn-submit align-items-center justify-content-center"
          >
            <p>{textButtonFailed}</p>
          </button>
        </div>
      </div>
    );
  }

  return (
    <VoltaModal
      isOpen={isOpen}
      toggle={toggle}
      defaultSubmitButton={false}
      renderBody={renderBody}
      customClass="failed-volta-modal"
      size="sm"
      onClosed={onClosed}
      unmountOnClose={unmountOnClose}
    />
  );
}

FailedModal.defaultProps = {
  isOpen: false,
  withTitle: false,
  toggle: () => {},
  onClosed: () => {},
  onFailedBtnClick: () => {},
  title: 'Gagal',
  description: 'Silahkan periksa kembali input anda',
  textButtonFailed: 'Cek Kembali',
  icon: Warning,
  withDesc: true,
  unmountOnClose: false,
};

FailedModal.propTypes = {
  isOpen: PropTypes.bool,
  withTitle: PropTypes.bool,
  toggle: PropTypes.func,
  onClosed: PropTypes.func,
  onFailedBtnClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  textButtonFailed: PropTypes.string,
  icon: PropTypes.string,
  withDesc: PropTypes.string,
  unmountOnClose: PropTypes.bool,
};
