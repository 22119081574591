import { SET_HEADER_NAME, SET_OPEN_MENU } from './headerConstants';

const headerInitialStates = {
  tabName: '',
  menuIsOpen: false,
  action: '',
};

function headerReducer(state = headerInitialStates, action) {
  const { payload, type } = action;
  const actions = {
    [SET_HEADER_NAME]: () => ({
      ...state,
      tabName: payload,
      action: type,
    }),
    [SET_OPEN_MENU]: () => ({
      ...state,
      menuIsOpen: payload ?? !state.menuIsOpen,
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { headerReducer };
