import {
  MenuMaster,
  MenuManajemen,
  MenuLayanan,
  MenuKonten,
  MenuAdministrator,
  MenuProfil,
  MenuMotor,
  MenuDealer,
  MenuBaterai,
  MenuPelanggan,
  MenuTransaksi,
} from '@volta-svg';

export const LIST_SIDEBAR = [
  {
    id: '1',
    value: 'Manajemen Motor',
    icon: MenuMotor,
    children: [
      {
        id: '1',
        to: '/motorcycle',
        value: 'Tipe Motor',
        headerName: 'Tipe Motor',
      },
      {
        id: '2',
        to: '/motorcycle-bundle',
        value: 'Bundle Motor',
        headerName: 'Bundle Motor',
      },
      {
        id: '3',
        to: '/dealer-motor',
        value: 'Motor',
        headerName: 'Motor',
      },
    ],
  },
  {
    id: '2',
    value: 'Manajemen Dealer',
    icon: MenuDealer,
    children: [
      {
        id: '1',
        to: '/dealer',
        value: 'Dealer',
        headerName: 'Dealer',
      },
    ],
  },
  {
    id: '3',
    value: 'Manajemen Baterai',
    icon: MenuBaterai,
    children: [
      {
        id: '1',
        to: '/battery',
        value: 'Baterai',
        headerName: 'Baterai',
      },
    ],
  },
  {
    id: '4',
    value: 'Manajemen Pelanggan',
    icon: MenuPelanggan,
    children: [
      {
        id: '1',
        to: '/customer',
        value: 'Data Pelanggan',
        headerName: 'Data Pelanggan',
      },
      {
        id: '2',
        to: '/manajement-profile',
        value: 'Data Pengguna Aplikasi',
        headerName: 'Data Pengguna Aplikasi',
      },
      {
        id: '3',
        to: '/transaction-history',
        value: 'Pencarian Pengguna',
        headerName: 'Pencarian Pengguna',
      },
    ],
  },
  {
    id: '5',
    value: 'Manajemen Layanan',
    icon: MenuLayanan,
    children: [
      {
        id: '1',
        to: '/service',
        value: 'Pemesanan',
        headerName: 'Pemesanan',
      },
      {
        id: '2',
        to: '/manual',
        value: 'Servis Registrasi',
        headerName: 'Servis Registrasi',
      },
    ],
  },
  {
    id: '6',
    value: 'Transaksi',
    icon: MenuTransaksi,
    children: [
      {
        id: '1',
        to: '/dashboard-telkomsel',
        value: 'Dashboard Telkomsel',
      },
      {
        id: '2',
        to: '/upgrade-volta-plus',
        value: 'Upgrade Volta Plus',
        headerName: 'Upgrade Volta Plus',
      },
      {
        id: '3',
        to: '/request-withdraw',
        value: 'Penarikan Saldo Pengguna',
        headerName: 'Penarikan Saldo Pengguna',
      },
      {
        id: '4',
        to: '/sgb-membership-purchase',
        value: 'Pembelian Membership',
        headerName: 'Pembelian Membership SGB',
      },
    ],
  },
  {
    id: '7',
    value: 'Manajemen Konten',
    icon: MenuBaterai,
    children: [
      {
        id: '1',
        to: '/article',
        value: 'Panduan',
        headerName: 'Panduan',
      },
      {
        id: '2',
        to: '/banner',
        value: 'Spanduk',
        headerName: 'Spanduk',
      },
    ],
  },
  {
    id: '8',
    value: 'Administrator',
    icon: MenuAdministrator,
    children: [
      {
        id: '1',
        to: '/audit-trail',
        value: 'Jejak Audit',
        headerName: 'Jejak Audit',
      },
      {
        id: '2',
        to: '/system-parameter',
        value: 'Sistem Parameter',
        headerName: 'Sistem Parameter',
      },
      {
        id: '3',
        to: '/broadcast-notification',
        value: 'Notifikasi Umum',
        headerName: 'Notifikasi Umum',
      },
      {
        id: '4',
        to: '/manage-user',
        value: 'Manage User',
        headerName: 'Manage User - under construction',
        hide: true,
      },
      {
        id: '5',
        to: '/master-application',
        value: 'Master Application',
        headerName: 'Master Appilcation - under construction',
        hide: true,
      },
    ],
  },
  {
    id: '9',
    value: 'Profil',
    icon: MenuProfil,
    children: [
      {
        id: '1',
        to: '/change-password',
        value: 'Ganti Kata Sandi',
        headerName: 'Ganti Kata Sandi',
      },
    ],
  },
];

export const LIST_SIDEBAR_FOR_ROLE_DEALER = [
  {
    id: '1',
    value: 'Manajemen',
    icon: MenuManajemen,
    children: [
      {
        id: '1',
        to: '/dealer-motor',
        value: 'Motor',
        headerName: 'Motor',
      },
      {
        id: '2',
        to: '/customer',
        value: 'Pelanggan',
        headerName: 'Pelanggan',
      },
    ],
  },
];
