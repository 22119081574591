import moment from 'moment';

const TIME_FORMAT = /^\d\d:\d\d$/g;

export const useDateChange = ({
  formData,
  setFormData,
  onDataChange,
  type,
}) => {
  function onDateChange(
    key,
    date,
    key2,
    flag = true,
    resetError = () => {},
    event = null
  ) {
    if (type === 'time' && event && event.target.value) {
      const textValue = event.target.value;
      if (!TIME_FORMAT.test(textValue)) {
        onDataChange(key, date);
        return;
      }
    }

    const d1 = date;
    const d2 = formData[key2];
    const m1 = moment(d1);
    const m2 = moment(d2);
    console.log({
      d1,
      d2,
      m1: m1.format('HH:mm'),
      m2: m2.format('HH:mm'),
      flag,
    });
    const diff = m1.diff(m2, 'second');
    if (flag) {
      if (diff <= 0) {
        onDataChange(key, date);
      } else {
        setFormData({
          ...formData,
          [key]: date,
          [key2]: date,
        });
      }
    } else {
      if (diff > 0) {
        onDataChange(key, date);
      } else {
        setFormData({
          ...formData,
          [key]: date,
          [key2]: date,
        });
      }
    }
    typeof resetError === 'function' && resetError();
  }

  return onDateChange;
};
