import React, { lazy, Suspense } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import './styles.scss';

const LogIn = lazy(() => import('@volta-module-auth/screens/LogIn'));

export default function AuthNavigation() {
  return (
    <HashRouter>
      <Suspense fallback={<React.Fragment />}>
        <Switch>
          <Route path="/log-in">
            <LogIn />
          </Route>
          <Redirect from="/" to="/log-in" />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}
