import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@volta-component-svg';
import './styles.scss';

export default function FormContent({
  renderForm,
  loading,
  withBoxCard,
  withOuterCard,
  style,
  className,
}) {
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div
      className={`form-content ${className} ${withBoxCard ? 'box-card' : ''} ${
        withOuterCard ? 'outer-card' : ''
      }`}
      style={style}
    >
      {renderForm()}
    </div>
  );
}

FormContent.defaultProps = {
  renderForm: () => null,
  loading: false,
  withBoxCard: false,
  withOuterCard: false,
  style: {},
  className: '',
};

FormContent.propTypes = {
  renderForm: PropTypes.func,
  loading: PropTypes.bool,
  withBoxCard: PropTypes.bool,
  withOuterCard: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};
