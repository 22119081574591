import React from 'react';
import { version } from '../../../../package.json';
import './styles.scss';

export const Footer = () => {
  return (
    <footer>
      <div className="footer">
        {/* <text className="txt-version">v{version}</text> */}
      </div>
    </footer>
  );
};
