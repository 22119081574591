import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { InputCheckbox } from '@volta-component-input';
import PropTypes from 'prop-types';
import './styles.scss';

export default function InputCheckBoxGroup({
  className,
  data: dataProps,
  title,
  onChange,
}) {
  const [data, setData] = React.useState(dataProps);

  useEffect(() => {
    setData(dataProps);
  }, [dataProps]);

  function onInputCheckSelected(newSelected, index) {
    const newData = data.map((d, i) =>
      i === index ? { ...d, isSelected: newSelected } : d
    );
    setData(newData);
    onChange(newData[index], newData);
  }

  return (
    <div className={`input-check-box-group ${className}`}>
      <h3>{title}</h3>
      {data.map((item, index) => (
        <Row key={item.id} className="mb-3">
          <Col className="d-flex flex-row align-items-center">
            <InputCheckbox
              isSelected={item.isSelected}
              onSelectedChange={(newSelected) => {
                onInputCheckSelected(newSelected, index);
              }}
              className="group-checkbox"
            />
            <div onClick={() => onInputCheckSelected(!item.isSelected, index)}>
              <label>{item.label}</label>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

InputCheckBoxGroup.defaultProps = {
  className: '',
  data: [],
  title: 'Configuration',
  onChange: () => {},
};

InputCheckBoxGroup.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(Object),
  title: PropTypes.string,
  onChange: PropTypes.func,
};
