import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

export default function InputDateTime({
  className,
  titleDate,
  titleTime,
  idDate,
  idTime,
  style,
  date,
  onDateChange,
  wrapped,
  isShowDate,
  isShowTime,
  dateClassName,
  timeClassName,
  dateClassWrapperName,
  timeClassWrapperName,
  isShowDateWrapper,
  isShowTimeWrapper,
  dateFormat,
  timeFormat,
  errorText,
  disabled,
}) {
  function renderDateComponent() {
    if (isShowDate) {
      return (
        <DatePicker
          selected={date}
          className={`${dateClassName} ${errorText && 'error-input'}`}
          id={idDate}
          dateFormat={dateFormat}
          onChange={(date, event) => onDateChange(date, event)}
          disabled={disabled}
          placeholderText="DD MM YYYY"
        />
      );
    }
    return null;
  }

  function renderTimeComponent() {
    if (isShowTime) {
      return (
        <>
          <DatePicker
            selected={date}
            dateFormat={timeFormat}
            showTimeSelect
            timeIntervals={1}
            showTimeSelectOnly
            className={timeClassName}
            id={idTime}
            timeCaption="Time"
            onChange={(date, event) => onDateChange(date, event)}
            disabled={disabled}
          />
        </>
      );
    }
    return null;
  }

  if (wrapped) {
    return (
      <div className={`row ${className}`} style={style}>
        {isShowDateWrapper && (
          <div className={dateClassWrapperName}>
            <label htmlFor={idDate} className="form-label">
              {titleDate}
            </label>
            {renderDateComponent()}
            {errorText && <p className="text-danger text-error">{errorText}</p>}
          </div>
        )}
        {isShowTimeWrapper && (
          <div className={timeClassWrapperName}>
            <label htmlFor={idTime} className="form-label">
              {titleTime}
            </label>
            {renderTimeComponent()}
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {renderDateComponent()}
      {renderTimeComponent()}
    </>
  );
}

InputDateTime.defaultProps = {
  className: '',
  idDate: '',
  idTime: '',
  titleDate: '',
  titleTime: '',
  style: {},
  date: null,
  wrapped: true,
  isShowDate: true,
  isShowTime: true,
  dateClassName: 'form-control',
  onDateChange: () => {},
  timeClassName: 'form-control',
  dateClassWrapperName: 'col-8',
  timeClassWrapperName: 'col-4',
  isShowDateWrapper: true,
  isShowTimeWrapper: true,
  dateFormat: 'dd MMMM yyyy',
  timeFormat: 'HH:mm',
  errorText: '',
  disabled: false,
};

InputDateTime.propTypes = {
  className: PropTypes.string,
  idDate: PropTypes.string,
  titleDate: PropTypes.string,
  idTime: PropTypes.string,
  titleTime: PropTypes.string,
  style: PropTypes.objectOf(Object),
  date: PropTypes.objectOf(Date),
  onDateChange: PropTypes.func,
  wrapped: PropTypes.bool,
  isShowDate: PropTypes.bool,
  isShowTime: PropTypes.bool,
  dateClassName: PropTypes.string,
  timeClassName: PropTypes.string,
  dateClassWrapperName: PropTypes.string,
  timeClassWrapperName: PropTypes.string,
  isShowDateWrapper: PropTypes.bool,
  isShowTimeWrapper: PropTypes.bool,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
};
