import React, { useEffect } from 'react';
import { InputCheckboxActionTable } from '@volta-component-input';
import PropTypes from 'prop-types';
import './styles.scss';

export default function InputCheckBoxGroupActionTable({
  className,
  data: dataProps,
  title,
  onChange,
}) {
  const [data, setData] = React.useState(dataProps);

  useEffect(() => {
    setData(dataProps);
  }, [dataProps]);

  function resetSelectData() {
    data.map((d, i) => {
      d.isSelected = false;
    });
  }

  function onInputCheckSelected(newSelected, index) {
    resetSelectData();
    const newData = data.map((d, i) =>
      i === index ? { ...d, isSelected: newSelected } : d
    );
    setData(newData);
    onChange(newData[index], newData);
  }

  return (
    <>
      <div className={`input-check-box-group-action-table ${className}`}>
        <h3>{title}</h3>
      </div>
      <div className="d-flex flex-column">
        {data.map((item, index) => (
          <InputCheckboxActionTable
            key={item.id}
            isSelected={item.isSelected}
            onSelectedChange={(newSelected) => {
              onInputCheckSelected(newSelected, index);
            }}
            className="group-checkbox"
            label={item.label}
          />
        ))}
      </div>
    </>
  );
}

InputCheckBoxGroupActionTable.defaultProps = {
  className: '',
  data: [],
  title: '',
  onChange: () => {},
};

InputCheckBoxGroupActionTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(Object),
  title: PropTypes.string,
  onChange: PropTypes.func,
};
