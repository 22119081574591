import React from 'react';
import VoltaModal from '../VoltaModal';
import PropTypes from 'prop-types';
import './styles.scss';
import { Image } from '@volta/component/cards';
import { Success } from '@volta/assets/svg';

export default function SuccessModal({
  isOpen,
  toggle,
  onClosed,
  onSuccessBtnClick,
  title,
  description,
  textButtonSuccess,
  icon,
  withTitle,
  withDesc,
  unmountOnClose,
}) {
  function renderBody() {
    return (
      <div className="body-success-modal">
        <Image src={icon} className="mb-4" />
        {withTitle && <h5 className="title-modal">{title}</h5>}
        {withDesc && <h6 className="desc-modal">{description}</h6>}
        <div className="d-flex flex-row submit-content">
          <button
            onClick={onSuccessBtnClick}
            className="d-flex btn btn-submit align-items-center justify-content-center"
          >
            <p>{textButtonSuccess}</p>
          </button>
        </div>
      </div>
    );
  }

  return (
    <VoltaModal
      isOpen={isOpen}
      toggle={toggle}
      defaultSubmitButton={false}
      renderBody={renderBody}
      customClass="success-volta-modal"
      size="sm"
      onClosed={onClosed}
      unmountOnClose={unmountOnClose}
    />
  );
}

SuccessModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  onClosed: () => {},
  onSuccessBtnClick: () => {},
  title: 'Sukses',
  description: 'Data Berhasil Disimpan',
  textButtonSuccess: 'Oke',
  icon: Success,
  withTitle: true,
  withDesc: true,
  unmountOnClose: false,
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onClosed: PropTypes.func,
  onSuccessBtnClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  textButtonSuccess: PropTypes.string,
  icon: PropTypes.string,
  withTitle: PropTypes.bool,
  withDesc: PropTypes.bool,
  unmountOnClose: PropTypes.bool,
};
