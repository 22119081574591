import { LIST_SIDEBAR } from '@volta-component-layout/Sidebar/data';

const LIST_PATHS = ((data) => {
  const paths = {};
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    for (let j = 0; j < d.children.length; j++) {
      const child = d.children[j];
      paths[child.to] = child.headerName;
    }
  }
  return paths;
})(LIST_SIDEBAR);

export default LIST_PATHS;
