var usersResponse = (filter) => {
  let filterKey = [];
  let filterValue = '';
  if (filter?.or?.length > 0) {
    for (let filt of filter?.or) {
      filterKey = [...filterKey, Object.keys(filt.users)[0]];
    }
    filterValue = filter?.or[0]?.users.userName.likeInsensitive;
  }

  const filterUsers = { users: { ...users.users } };
  const filteredUsers = filterUsers.users.nodes.filter((value) => {
    return (value['userName'] && value['nameUser'])
      .toLowerCase()
      .includes(filterValue.toLowerCase());
  });
  filterUsers.users.nodes = filteredUsers;
  return filterValue === '' ? { data: users } : { data: filterUsers };
};

var userDetailResponse = (id = 1) => {
  switch (id) {
    case 1:
      return {
        data: userDetailOne,
      };
    case 2:
      return {
        data: userDetailTwo,
      };
    case 3:
      return {
        data: userDetailThree,
      };
    case 4:
      return {
        data: userDetailFour,
      };
    case 5:
      return {
        data: userDetailFive,
      };

    default:
      return {
        data: userDetailOne,
      };
  }
};

var createUserResponse = (data) => {
  if (data !== null) {
    data.id = users.users.nodes.length + 1;
    users.users.nodes = [...users.users.nodes, data];
  }
  console.log('users -> ', users);
  return {
    data: data,
    meta: metaSuccess,
  };
};

var updateUserResponse = (id, data) => {
  return {
    data: data,
    meta: metaSuccess,
  };
};

var users = {
  users: {
    nodes: [
      {
        id: 1,
        userName: 'ops_monitoring',
        nameUser: 'Ops Monitoring',
        groupName: 'VOLTA',
        rolesName: 'Supervisor',
        email: 'ops_monitoring@gmail.com',
        mobileNumber: '6287999887920',
        isActive: true,
      },
      {
        id: 2,
        userName: 'nugroho',
        nameUser: 'Nugroho Aditya',
        groupName: 'VOLTA',
        rolesName: 'Super Admin',
        email: 'nugroho@gmail.com',
        mobileNumber: '6287999887945',
        isActive: true,
      },
      {
        id: 3,
        userName: 'annisabayyina',
        nameUser: 'Annisa Bayyina Azis',
        groupName: 'VOLTA',
        rolesName: 'Admin',
        email: 'abayyina@gmail.com',
        mobileNumber: '6287888336327',
        isActive: false,
      },
      {
        id: 4,
        userName: 'vis_roxy_tofa',
        nameUser: 'Agus Mustofa',
        groupName: 'VIS VOLTA',
        rolesName: 'Supervisor',
        email: 'tofa@roxy.COM',
        mobileNumber: '6281234516678',
        isActive: true,
      },
      {
        id: 5,
        userName: 'cs_sicepat_A',
        nameUser: 'SC Sicepat A',
        groupName: 'SiCepat',
        rolesName: 'Customer Services',
        email: 'a@sicepat.com',
        mobileNumber: '6281233451609',
        isActive: true,
      },
    ],
    totalCount: 5,
  },
};

var userDetailOne = {
  userDetail: {
    id: 1,
    userName: 'ops_monitoring',
    nameUser: 'OPS Monitoring',
    email: 'ops_monitoring@gmail.com',
    gender: 'L',
    address: 'Jl Abc Jakarta Barat',
    groupName: {
      id: 1,
      name: 'VOLTA',
    },
    rolesName: {
      id: 1,
      name: 'Supervisor',
    },
    userGroupName: {
      id: 1,
      name: 'User Group',
    },
    imagePath: 'upload/7820221204132913.482.jpg',
    isActive: true,
  },
};

var userDetailTwo = {
  userDetail: {
    id: 2,
    userName: 'nugroho',
    nameUser: 'Nugroho Aditya',
    email: 'nugroho@gmail.com',
    gender: 'L',
    address: 'Jl Mawar Jakarta Timur',
    groupName: {
      id: 1,
      name: 'VOLTA',
    },
    rolesName: {
      id: 2,
      name: 'Super Admin',
    },
    userGroupName: {
      id: 1,
      name: 'User Group',
    },
    imagePath: null,
    isActive: true,
  },
};

var userDetailThree = {
  userDetail: {
    id: 3,
    userName: 'annisabayyina',
    nameUser: 'Annisa Bayyina Azis',
    email: 'abayyina@gmail.com',
    gender: 'P',
    address: 'Jl Duri Jakarta Barat',
    groupName: {
      id: 1,
      name: 'VOLTA',
    },
    rolesName: {
      id: 3,
      name: 'Admin',
    },
    userGroupName: {
      id: 1,
      name: 'User Group',
    },
    imagePath: null,
    isActive: false,
  },
};

var userDetailFour = {
  userDetail: {
    id: 4,
    userName: 'vis_roxy_tofa',
    nameUser: 'Agus Mustofa',
    email: 'tofa@roxy.COM',
    gender: 'L',
    address: 'Jl Abc Jakarta Barat',
    groupName: {
      id: 2,
      name: 'VIS VOLTA',
    },
    rolesName: {
      id: 1,
      name: 'Supervisor',
    },
    userGroupName: {
      id: 1,
      name: 'User Group',
    },
    imagePath: null,
    isActive: true,
  },
};

var userDetailFive = {
  userDetail: {
    id: 5,
    userName: 'cs_sicepat_A',
    nameUser: 'SC Sicepat A',
    email: 'a@sicepat.com',
    gender: 'L',
    address: 'Jl Abc Jakarta Barat',
    groupName: {
      id: 3,
      name: 'Sicepat',
    },
    rolesName: {
      id: 4,
      name: 'Customer Services',
    },
    userGroupName: {
      id: 1,
      name: 'User Group',
    },
    imagePath: null,
    isActive: true,
  },
};

var userGroupsResponse = {
  data: {
    userGroups: {
      nodes: [
        {
          id: 1,
          name: 'User Group 1',
        },
        {
          id: 2,
          name: 'User Group 2',
        },
      ],
    },
  },
};

var roleGroupsResponse = {
  data: {
    roleGroups: {
      nodes: [
        {
          id: 1,
          name: 'VOLTA',
        },
        {
          id: 2,
          name: 'VIS VOLTA',
        },
        {
          id: 3,
          name: 'Sicepat',
        },
      ],
    },
  },
};

var rolesResponse = {
  data: {
    roles: {
      nodes: [
        {
          id: 1,
          name: 'Supervisor',
        },
        {
          id: 2,
          name: 'Super Admin',
        },
        {
          id: 3,
          name: 'Admin',
        },
        {
          id: 4,
          name: 'Customer Services',
        },
      ],
    },
  },
};

var metaSuccess = {
  info: null,
  message: 'Request succesfully proceed',
  success: true,
};

export {
  usersResponse,
  userDetailResponse,
  userGroupsResponse,
  roleGroupsResponse,
  rolesResponse,
  createUserResponse,
  updateUserResponse,
};
