import { SET_PARAMETER } from '../extra-constants/system-parameter';

const initialState = {
  listParameters: [],
  action: '',
};

function parameterReducer(state = initialState, action) {
  const { type, payload } = action;
  const actions = {
    [SET_PARAMETER]: () => ({
      ...state,
      listParameters: payload,
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { parameterReducer };
