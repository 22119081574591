import { combineReducers, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { headerReducer } from '@volta-component-layout/Header/headerReducer';
import { cityReducer } from './extra-reducers/city';
import { provinceReducer } from './extra-reducers/province';
import { colorReducer } from './extra-reducers/color';
import { authReducer } from './extra-reducers/auth';
import { parameterReducer } from './extra-reducers/system-parameter';

const reducers = combineReducers({
  header: headerReducer,
  city: cityReducer,
  province: provinceReducer,
  color: colorReducer,
  auth: authReducer,
  paramater: parameterReducer,
});

const persistConfig = {
  key: 'volta-web-app',
  storage,
  whitelist: ['auth', 'paramater', 'header'],
};

const composeEnhancer =
  // eslint-disable-next-line no-undef
  (process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, composeEnhancer());
const persistor = persistStore(store);

export { reducers, persistedReducer, persistor };

export default store;
