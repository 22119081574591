import { SET_CITIES, SET_CITIES_BY_PROVINCE } from '../extra-constants/city';

const cityInitialStates = {
  data: [],
  dataByProvince: {},
  action: '',
};

function cityReducer(state = cityInitialStates, action) {
  const { payload, type } = action;
  const actions = {
    [SET_CITIES]: () => ({
      ...state,
      data: payload,
      action: type,
    }),
    [SET_CITIES_BY_PROVINCE]: () => ({
      ...state,
      dataByProvince: {
        ...state.dataByProvince,
        [payload.provinceId]: payload.data,
      },
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { cityReducer };
