import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiNode, {
  apiGo,
  apiNodeStb,
  apiGoStb,
  apiGoStbSemolis,
  apiNodeMock,
  apiGoMock,
} from '@volta-redux/api';
import { logOut, setSession } from '@volta-module-auth/actions';
import { useDateChange } from './data';
import {
  useCity,
  useColors,
  useProvinces,
  useLoggedUserGroup,
  useSystemParameter,
} from './api';
import swal from 'sweetalert';
import { createSwalButtons } from '../helper';

export const usePrevious = (val) => {
  const r = useRef();
  useEffect(() => {
    r.current = val;
  });
  return r.current;
};

export const useFileSelector = () => {
  const f = useRef(document.createElement('input'));

  f.current.type = 'file';

  return f.current;
};

export const useAxiosCreation = (
  api,
  _defaultHeader,
  url,
  method,
  timeout,
  apiType
) => {
  const [states, setStates] = useState({
    loading: false,
    response: {},
    customResponse: '',
    error: null,
    status: 1,
  });

  function withFormData(_headers, data, method = 'post', urlParam = null) {
    const formHeader = {
      ..._headers,
      'Content-type': 'multipart/form-data',
    };
    const _url = urlParam || url;
    const formData = new FormData();
    for (let key in data) {
      const d = data[key];
      formData.append(key, d);
    }

    let sent = api[method];
    if (sent) {
      return sent(_url, formData, {
        headers: formHeader,
      });
    }
    return null;
  }

  function callApi(
    data,
    headers = null,
    urlParam = null,
    customResponse = null
  ) {
    let a = null;
    const defaultHeader = _defaultHeader;
    const _headers = headers
      ? {
          ...defaultHeader,
          ...headers,
        }
      : defaultHeader;
    const _url = urlParam || url;
    switch (method) {
      case 'get':
        a = api.get(_url, {
          params: data,
          headers: _headers,
        });
        break;
      case 'post':
        a = api.post(_url, data, {
          headers: _headers,
        });
        break;
      case 'postformdata':
        a = withFormData(_headers, data, 'post', _url);
        break;
      default:
      case 'put':
        a = api.put(_url, data, {
          headers: _headers,
        });
        break;
      case 'putformdata':
        a = withFormData(_headers, data, 'put', _url);
        break;
      case 'delete':
        a = api.delete(_url, {
          data,
          headers: _headers,
        });
        break;
    }
    setStates({
      ...setStates,
      loading: true,
      status: -1,
    });
    return new Promise((resolve, reject) => {
      a.then((r) => {
        setTimeout(() => {
          switch (apiType) {
            case 'semolis':
              if (r.data.data && !r.data.errors) {
                setStates({
                  ...states,
                  loading: false,
                  response: r.data,
                  status: 1,
                  customResponse: customResponse,
                });
                resolve(r.data);
              } else {
                setStates({
                  ...states,
                  loading: false,
                  error: r.data,
                  status: [1000, 1001, 1003].includes(r.data?.http_status)
                    ? 2
                    : 0,
                  customResponse: customResponse,
                });
                reject(r.data);
              }
              break;
            case 'volta':
              if (r.data && !r.data.errors) {
                setStates({
                  ...states,
                  loading: false,
                  response: r.data,
                  status: 1,
                  customResponse: customResponse,
                });
                resolve(r.data);
              } else {
                setStates({
                  ...states,
                  loading: false,
                  error: r.data,
                  status: 0,
                  customResponse: customResponse,
                });
                reject(r.data);
              }
              break;
            default:
              if (r.data && !r.data.errors) {
                setStates({
                  ...states,
                  loading: false,
                  response: r.data,
                  status: 1,
                  customResponse: customResponse,
                });
                resolve(r.data);
              } else {
                setStates({
                  ...states,
                  loading: false,
                  error: r.data,
                  status: 0,
                  customResponse: customResponse,
                });
                reject(r.data);
              }
              break;
          }
        }, timeout);
      }).catch((err) => {
        setTimeout(() => {
          switch (apiType) {
            case 'semolis':
              setStates({
                ...states,
                loading: false,
                error: err?.response?.data,
                status: err?.response?.status === 401 ? 2 : 0,
                customResponse: customResponse,
              });
              reject(err);
              break;
            case 'volta':
              setStates({
                ...states,
                loading: false,
                error: err?.response?.data,
                status: err?.response?.status === 401 ? 2 : 0,
                customResponse: customResponse,
              });
              reject(err);
              break;
            default:
              setStates({
                ...states,
                loading: false,
                error: err?.response?.data,
                status: err?.response?.status === 401 ? 2 : 0,
                customResponse: customResponse,
              });
              reject(err);
              break;
          }
        }, timeout);
      });
    });
  }

  return [
    callApi,
    states.loading,
    states.status,
    states.response,
    states.error,
    states.customResponse,
  ];
};

export const useHeaderWithAuthorization = () => {
  const session = useSelector((state) => state.auth.session);
  function isAuthenticated() {
    const u = session.user;
    if (u && typeof u === 'object') {
      return true;
    }
    return false;
  }

  function getDefaultHeaders() {
    let headers = {
      'Content-type': 'application/json',
      apiKey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
    };
    if (isAuthenticated()) {
      const u = session.user;
      headers = {
        ...headers,
        Authorization: `Bearer ${u.access_token}`,
      };
    }
    return headers;
  }

  return [getDefaultHeaders, isAuthenticated];
};

export const useNodeAxios = (
  na,
  url = '/graphql',
  method = 'post',
  timeout = 1000
) => {
  const [getDefaultHeaders, isAuthenticated] = useHeaderWithAuthorization();
  const dispatch = useDispatch();

  const res = useAxiosCreation(na, getDefaultHeaders(), url, method, timeout);
  const prevApiStatus = usePrevious(res[2]);

  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(logOut());
    }
  }, []);

  useEffect(() => {
    const apiStatus = res[2];
    if (prevApiStatus !== undefined && apiStatus !== prevApiStatus) {
      if (apiStatus === 2) {
        swal({
          title: 'Sesi login Anda telah habis',
          text: 'Harap login ulang.',
          icon: 'error',
          buttons: createSwalButtons('Oke'),
        }).then(() => {
          dispatch(
            setSession({
              key: 'user',
              data: null,
            })
          );
          dispatch(logOut());
        });
      }
    }
  }, [res[2], prevApiStatus]);

  return res;
};

export const useAxios = (
  url = '/graphql',
  method = 'post',
  timeout = 1000,
  withMockApi = false
) => {
  return useNodeAxios(
    !withMockApi ? apiNode : apiNodeMock,
    url,
    method,
    timeout
  );
};

export const useAxios3 = (
  url = '/graphql',
  method = 'post',
  timeout = 1000
) => {
  return useNodeAxios(apiNodeStb, url, method, timeout);
};

export const useGoAxios = (
  api,
  url = '/',
  method = 'post',
  withAuthorization = false,
  timeout = 1000,
  apiType = 'volta'
) => {
  const [getDefaultHeadersWithAuthorization] = useHeaderWithAuthorization();
  function getDefaultHeaders() {
    let headers = {
      ...(withAuthorization ? getDefaultHeadersWithAuthorization() : {}),
      'Content-type': 'application/json',
      apiKey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
    };

    return headers;
  }

  const res = useAxiosCreation(
    api,
    getDefaultHeaders(),
    url,
    method,
    timeout,
    apiType
  );

  const prevApiStatus = usePrevious(res[2]);
  const dispatch = useDispatch();

  useEffect(() => {
    const apiStatus = res[2];
    if (prevApiStatus !== undefined && apiStatus !== prevApiStatus) {
      if (apiStatus === 2 && url !== 'v1/auth/login-admin') {
        swal({
          title: 'Sesi login Anda telah habis',
          text: 'Harap login ulang.',
          icon: 'error',
        }).then(() => {
          dispatch(
            setSession({
              key: 'user',
              data: null,
            })
          );
          dispatch(logOut());
        });
      }
    }
  }, [res[2], prevApiStatus]);

  return res;
};

export const useAxios2 = (
  url = '/',
  method = 'post',
  withAuthorization = false,
  timeout = 1000,
  withMockApi = false
) => {
  return useGoAxios(
    !withMockApi ? apiGo : apiGoMock,
    url,
    method,
    withAuthorization,
    timeout
  );
};

export const useAxios4 = (
  url = '/',
  method = 'post',
  withAuthorization = false,
  timeout = 1000
) => {
  return useGoAxios(apiGoStb, url, method, withAuthorization, timeout);
};

export const useAxios5 = (
  url = '/',
  method = 'post',
  withAuthorization = false,
  apiType = 'volta',
  timeout = 1000
) => {
  return useGoAxios(
    apiGoStbSemolis,
    url,
    method,
    withAuthorization,
    timeout,
    apiType
  );
};

export const useDebounce = (callback, timeout = 500) => {
  const t = useRef();
  function startDebounce(e) {
    if (t.current) {
      clearTimeout(t.current);
    }
    t.current = setTimeout(() => {
      callback(e);
    }, timeout);
  }

  return startDebounce;
};

export const useDebounceAlt = (val, delay) => {
  const [debounceVal, setDebounceVal] = useState(val);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVal(val);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [val]);

  return debounceVal;
};

export const useLimitTableData = (
  useSystemParameterLimit = true,
  limitProps = 8
) => {
  const [limitParameter, pLoading] = useSystemParameter();

  const limit = useMemo(() => {
    if (!useSystemParameterLimit) {
      return limitProps;
    }
    const l = limitParameter.find((item) => item.name === 'LimitDisplayData');
    return Number(l ? l.pValue : 8);
  }, [limitParameter]);

  return [limit, pLoading];
};

function useWindowSize() {
  // source: https://dev.to/3sanket3/usewindowsize-react-hook-to-handle-responsiveness-in-javascript-3dcl
  const isWindowClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? window.innerWidth : undefined
  );
  const [windowHeight, setWindowHeight] = useState(
    isWindowClient ? window.innerHeight : undefined
  );

  //👇
  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setWindowSize(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener('resize', setSize);

      //un-register the listener
      return () => window.removeEventListener('resize', setSize);
    }
  }, [isWindowClient, setWindowSize, setWindowHeight]);
  //☝️

  return [windowSize, windowHeight];
}

export {
  useCity,
  useColors,
  useProvinces,
  useDateChange,
  useLoggedUserGroup,
  useSystemParameter,
  useWindowSize,
};
