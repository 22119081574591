import { mock, mockGo } from '..';
import {
  usersResponse,
  createUserResponse,
  roleGroupsResponse,
  rolesResponse,
  updateUserResponse,
  userDetailResponse,
  userGroupsResponse,
} from './mock-responses';

var getUsersMock = (filter) => {
  return mock
    .onPost('/graphql', {
      id: 'getUsersMock',
      var: {
        first: 10,
        offset: 0,
        filter: filter,
      },
    })
    .reply(200, usersResponse(filter));
};

var getUserDetailMock = (id = 1) => {
  return mock
    .onPost('/graphql', {
      id: 'getUserDetailMock',
      var: {
        id: Number(id),
      },
    })
    .reply(200, userDetailResponse(id));
};

var getUserGroupsMock = () => {
  return mock
    .onPost('/graphql', {
      id: 'getUserGroupsMock',
      var: {},
    })
    .reply(200, userGroupsResponse);
};

var getRoleGroupsMock = () => {
  return mock
    .onPost('/graphql', {
      id: 'getRoleGroupsMock',
      var: {},
    })
    .reply(200, roleGroupsResponse);
};

var getRolesMock = () => {
  return mock
    .onPost('/graphql', {
      id: 'getRolesMock',
      var: {},
    })
    .reply(200, rolesResponse);
};

var createUser = (data = null) => {
  const formData = new FormData();
  for (let key in data) {
    const d = data[key];
    formData.append(key, d);
  }
  return mockGo
    .onPost('/user/create', formData)
    .reply(200, createUserResponse(data));
};

var updateUser = (id = null, data = null) => {
  const formData = new FormData();
  for (let key in data) {
    const d = data[key];
    formData.append(key, d);
  }
  return mockGo
    .onPut(`/user/update/${id}`, formData)
    .reply(200, updateUserResponse(id, data));
};

const startMockManageUserServicesApi = () => {
  getUsersMock();
  getUserDetailMock();
  getUserGroupsMock();
  getRoleGroupsMock();
  getRolesMock();
  // createUser();
  // updateUser();
};

export {
  getUsersMock,
  getUserDetailMock,
  getUserGroupsMock,
  getRoleGroupsMock,
  getRolesMock,
  createUser,
  updateUser,
};
export default startMockManageUserServicesApi;
