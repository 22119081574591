import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useFileSelector } from '@volta-utils/hooks';
import { /_GO } from '@volta-redux/api';
import './styles.scss';
import { Image } from '@volta/component/cards';
import { Person } from '@volta/assets/svg';
import { LoadingOnText } from '@volta/component/svg';

export default function InputImageAlternative({
  className,
  btnTitle,
  onFileSelected,
  src,
  errorRes,
  title,
  temporaryImageUrl,
  noFileSelectedLabel,
}) {
  const fileInput = useFileSelector();
  const [fileUrl, setFileUrl] = useState(src);
  const [fileBlob, setFileBlob] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    // console.log('img', { src });
    if (typeof src === 'string' && src.indexOf(/_GO) !== -1) {
      axios
        .get(src, {
          responseType: 'blob',
          headers: {
            apikey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
          },
        })
        .then((response) => {
          // console.log('image res', response);
          const imageData = response.data;
          const imageUrl = URL.createObjectURL(imageData);
          setFileUrl(imageUrl);
        })
        .finally(() => {
          setImageLoading(false);
        });
      setImageLoading(true);
    } else {
      setFileBlob(src);
    }
  }, [src]);

  useEffect(() => {
    temporaryImageUrl(fileUrl);
  }, [fileUrl]);

  fileInput.accept = 'image/png, image/jpeg, iamge/jpg';

  fileInput.onchange = (e) => {
    const file = e.target.files[0];
    if (!file || file?.type?.indexOf('image') === -1) {
      alert('Mohon masukan file gambar');
      return;
    }
    const filereader = new FileReader();
    filereader.readAsArrayBuffer(file);
    filereader.onload = (readerEvent) => {
      const content = readerEvent.target.result;
      const blobContent = new Blob([content]);
      console.log('content -> ', content);
      console.log('blob content -> ', blobContent);
      setFileBlob(blobContent);
      setFileUrl(URL.createObjectURL(blobContent));
      onFileSelected({ fileBlob, file, fileUrl });
    };
  };

  function renderImage() {
    if (imageLoading) {
      return <LoadingOnText width="20px" height="20px" />;
    }
    return typeof fileUrl === 'string' && fileUrl ? (
      <img src={fileUrl} alt="user-img" className="image" />
    ) : imageLoading ? (
      <LoadingOnText width="20px" height="20px" />
    ) : (
      <Image src={Person} alt="default-user-img" />
    );
    //   <p style={{ textAlign: 'center' }} className="text-primary">
    //     {/* Klik tombol dibawah untuk memasukan gambar */}
    //   </p>
  }

  function NoFileLabel() {
    return !noFileSelectedLabel ? (
      <label className="flex-1 mt-2 form-label">
        Tidak ada file yang dipilih
      </label>
    ) : (
      noFileSelectedLabel
    );
  }

  return (
    <div className={className}>
      <div className="d-flex flex-row">
        <div className="col-3">
          <div
            className={`input-image-alternative d-flex align-items-center justify-content-center ${
              errorRes ? 'error' : ''
            }`}
          >
            {renderImage()}
          </div>
        </div>
        <div className="d-flex flex-column" style={{ marginLeft: '-10px' }}>
          <label className="form-label">{title}</label>
          <div className="mt-2">
            <button
              onClick={() => fileInput.click()}
              className="btn btn-upload ms-auto d-block"
            >
              {btnTitle}
            </button>
          </div>
          {fileUrl !== 'string' && !fileUrl && <NoFileLabel />}

          {errorRes && (
            <span className="flex-1 mt-3 text-danger">{errorRes}</span>
          )}
        </div>
      </div>
    </div>
  );
}

InputImageAlternative.defaultProps = {
  className: '',
  btnTitle: 'Pilih Gambar',
  errorRes: '',
  src: '',
  title: '',
  onFileSelected: () => {},
  temporaryImageUrl: () => {},
  noFileSelectedLabel: '',
};

InputImageAlternative.propTypes = {
  className: PropTypes.string,
  errorRes: PropTypes.string,
  btnTitle: PropTypes.string,
  onFileSelected: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
  temporaryImageUrl: PropTypes.func,
  noFileSelectedLabel: PropTypes.element,
};
