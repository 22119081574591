import React from 'react';
import PropTypes from 'prop-types';
import { CheckListWhite, ChecklistGreen } from '@volta-svg';
import { Image } from '@volta-component-card';
import './styles.scss';

export default function InputCheckboxActionTable({
  className,
  isSelected,
  onSelectedChange,
  label,
}) {
  return (
    <div
      onClick={() => {
        onSelectedChange(!isSelected);
      }}
      className={`d-flex justify-content-center align-items-center mt-3 ${
        isSelected ? 'selected' : ''
      }`}
    >
      {isSelected && (
        <div className="d-flex flex-row volta-checkbox-action-table-label selected">
          <label className="flex-1">{label}</label>
          <Image src={ChecklistGreen} />
        </div>
      )}
      {!isSelected && (
        <div className="volta-checkbox-action-table-label">
          <label>{label}</label>
        </div>
      )}
    </div>
  );
}

InputCheckboxActionTable.defaultProps = {
  className: '',
  isSelected: false,
  onSelectedChange: () => {},
  label: 'label',
};

InputCheckboxActionTable.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelectedChange: PropTypes.func,
  label: PropTypes.string,
};
