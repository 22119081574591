import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import './styles.scss';
import { VoltaModal } from '@volta/component/layouts';
import { useWindowSize } from '@volta/utils/hooks';
import { InputCheckboxGroupActionTable } from '@volta-component-input';
import Image from '../Image';
import { MoreVertical } from '@volta/assets/svg';

export default function DropdownForTable({ data }) {
  let [isOpen, setIsOpen] = useState(false);
  let [isOpenModal, setIsOpenModal] = useState(false);
  const [windowSize] = useWindowSize();
  const isMobile = windowSize < 543;
  const [action, setAction] = useState([]);

  useEffect(() => {
    data.map((item, index) => {
      action.push({
        id: index,
        label: item.title,
        key: item.title,
        isSelected: false,
        onClick: item.onClick,
      });
    });
  }, []);

  function renderDropdown() {
    if (isMobile) {
      isOpen = false;
    } else {
      isOpenModal = false;
    }
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          <Dropdown
            direction="left"
            isOpen={isOpen}
            toggle={() => {
              isMobile ? setIsOpenModal(!isOpenModal) : setIsOpen(!isOpen);
            }}
          >
            <DropdownToggle
              tag="div"
              data-toggle="dropdown"
              aria-expanded={isOpen}
            >
              <div className="dropdown-customer">
                <Image src={MoreVertical} />
                {/* <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span> */}
              </div>
            </DropdownToggle>
            <DropdownMenu container="body">
              {data.map((item, index) => (
                <DropdownItem key={index} onClick={item.onClick}>
                  {item.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <VoltaModal
          isOpen={isOpenModal}
          title="Aksi"
          toggle={() => setIsOpenModal(!isOpenModal)}
          defaultSubmitButton={false}
          // onModalButtonClick={() => setShowDetailModal(false)}
          // defaultSubmitButton={false}
          renderBody={renderModalActionBody}
          centered={true}
          centeredTitle={true}
          fullscreen="md-down"
        />
      </>
    );
  }

  function renderModalActionBody() {
    return (
      <>
        <div className="dropdown-table-modal">
          <InputCheckboxGroupActionTable
            onChange={(newD) => {
              setAction(action.map((c) => (c.id === newD.id ? newD : c)));
            }}
            data={action}
            className="configuration"
          />
          <Button
            onClick={() => {
              action.map((action) => {
                action.isSelected ? action.onClick() : {};
              });
            }}
            className="btn-submit mt-3"
            color="primary"
          >
            <p>Lanjutkan</p>
          </Button>
        </div>
      </>
    );
  }

  return renderDropdown();
}

DropdownForTable.defaultProps = {
  data: [],
};

DropdownForTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};
