import React, { useState } from 'react';
import {
  Badge,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import InputText from '../InputText';
import '../InputSelectSearch/styles.scss';
import { Search } from '@volta/assets/svg';
import SkeletonText from '@volta/component/layouts/SkeletonLoading/SkeletonText';

// *** The main difference between InputSelect (V1) and InputSelectV2:
// - The selected value in the second version is controlled directly by the parent component, while in the first version is statefull (it uses state)
// - To manipulate optionsData, on this version uses labelKey and valueKey, whereas in the first version it still uses a callback function

export default function InputSelectSearchV2({
  optionsData,
  selected,
  labelKey,
  valueKey,
  onOptionSelected,
  onOptionUnselected,
  className,
  disabled,
  title,
  id,
  placeholderSearch,
  placeholderOptions,
  titleOptions,
  withSearching,
  isHorizontalInput,
  isMultiSelect,
  errorText,
  isOptionLoading,
  onSearchChange,
  ...args
}) {
  const [searchText, setSearchText] = useState('');
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onItemSelected = (item) => {
    setSearchText('');
    onOptionSelected(item);
  };

  const handleSearchChange = (val) => {
    setSearchText(val);
    onSearchChange(val);
  };

  const handleMultipleItemUnselect = (newValue) => {
    let itemsForMultiSelect = [...selected];
    itemsForMultiSelect.splice(
      itemsForMultiSelect.findIndex((i) => i[valueKey] === newValue),
      1
    );
    onOptionUnselected(itemsForMultiSelect);
  };

  function renderDashboardOptions() {
    const displayOptions = optionsData
      .map((item) => {
        if (item[labelKey].toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);

    function renderOption(item) {
      if (item === undefined) return 'No result';
      return item[labelKey];
    }

    function toggleHandler() {
      setOptionsOpen((prevState) => !prevState);
    }

    return (
      <>
        <Dropdown
          isOpen={optionsOpen}
          toggle={toggleHandler}
          disabled={disabled}
          direction="down"
        >
          <DropdownToggle className="select-search" caret>
            {!isMultiSelect && selected ? (
              <span className="field-value">{selected[labelKey]}</span>
            ) : isMultiSelect && selected.length > 0 ? (
              <div className="d-flex flex-wrap gap-2">
                {selected.map((item, index) => (
                  <Badge key={index}>
                    {item[labelKey]}
                    <a
                      className="btn btn-close btn-close-sm ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMultipleItemUnselect(item[valueKey]);
                      }}
                    />
                  </Badge>
                ))}
              </div>
            ) : (
              <span className="field-value">{placeholderOptions}</span>
            )}
          </DropdownToggle>
          <DropdownMenu className="select-search-menu" {...args}>
            {withSearching && (
              <DropdownItem text>
                <div className="search-input">
                  <img src={Search} />
                  <InputText
                    title=" "
                    placeholder={placeholderSearch}
                    onValueChange={(val) => {
                      handleSearchChange(val);
                    }}
                    value={searchText}
                  />
                </div>
              </DropdownItem>
            )}
            <DropdownItem header className="title-options">
              {titleOptions}
            </DropdownItem>
            {isOptionLoading ? (
              <div className="d-flex flex-column gap-3 m-3 mt-0">
                <SkeletonText width="100%" />
                <SkeletonText width="88%" />
                <SkeletonText width="94%" />
              </div>
            ) : displayOptions.length > 0 ? (
              displayOptions.map((item, index) => {
                return (
                  <DropdownItem
                    key={`option-item-${index}`}
                    onClick={() => {
                      if (isMultiSelect) {
                        let itemsForMultiSelect = selected;
                        let checkDuplicate = itemsForMultiSelect.find(
                          (i) => i[valueKey] === item[valueKey]
                        );

                        if (checkDuplicate === undefined) {
                          itemsForMultiSelect.push(item);
                        }

                        onItemSelected(itemsForMultiSelect);
                      } else {
                        onItemSelected(item);
                      }
                    }}
                  >
                    {renderOption(item)}
                  </DropdownItem>
                );
              })
            ) : (
              <DropdownItem header className="txt-no-data">
                No result
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }

  return isHorizontalInput ? (
    <div className="form-group">
      <Row>
        <Col className="col-md-2 col-12 align-self-center">
          {title && (
            <label htmlFor={id} className="form-label label-input-horizontal">
              {title}
            </label>
          )}
        </Col>
        <Col className="col-md-10">{renderDashboardOptions()}</Col>
      </Row>
      {errorText !== '' && (
        <p className="text-danger text-error mb-0">{errorText}</p>
      )}
    </div>
  ) : (
    <div className="form-group">
      {title && (
        <label htmlFor={id} className="form-label">
          {title}
        </label>
      )}
      {renderDashboardOptions()}
      {errorText !== '' && (
        <p className="text-danger text-error mb-0">{errorText}</p>
      )}
    </div>
  );
}

InputSelectSearchV2.defaultProps = {
  optionsData: [],
  labelKey: 'label',
  valueKey: 'value',
  onOptionSelected: () => {},
  onOptionUnselected: () => {},
  className: '',
  disabled: false,
  title: '',
  id: '',
  placeholderSearch: 'pencarian',
  placeholderOptions: '--- silahkan pilih ---',
  titleOptions: 'silahkan pilih',
  withSearching: true,
  isHorizontalInput: false,
  isMultiSelect: false,
  errorText: '',
  isOptionLoading: false,
  onSearchChange: () => {},
};

InputSelectSearchV2.propTypes = {
  optionsData: PropTypes.arrayOf(Object).isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.objectOf(Object),
    PropTypes.arrayOf(Object),
  ]),
  onOptionSelected: PropTypes.func,
  onOptionUnselected: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  placeholderSearch: PropTypes.string,
  placeholderOptions: PropTypes.string,
  titleOptions: PropTypes.string,
  withSearching: PropTypes.bool,
  isHorizontalInput: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  errorText: PropTypes.string,
  isOptionLoading: PropTypes.bool,
  onSearchChange: PropTypes.func,
};
