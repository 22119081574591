import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@volta-component-layout';
import { Info } from '@volta/assets/svg';

export default function InputSelect({
  title,
  id,
  data,
  keyField,
  labelField,
  ariaLabel,
  onItemSelected,
  className,
  selected,
  style,
  disabled,
  errorText: errorTextProps,
  placeholder,
  withPlaceholder,
  tooltip,
  isDangerIndicator: isDangerIndicatorProps,
}) {
  const [displayedData, setDisplayedData] = useState(data);
  const [errorText, setErrorText] = useState(errorTextProps);
  const [isDangerIndicator, setIsDangerIndicator] = useState(
    isDangerIndicatorProps
  );
  const [isFocus, setFocused] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.selectedIndex = getSelectedIndex();
    }
  }, []);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  useEffect(() => {
    setIsDangerIndicator(isDangerIndicatorProps);
  }, [isDangerIndicatorProps]);

  useEffect(() => {
    if (isDangerIndicator) {
      applyDangerIndicator();
    }
  }, [isDangerIndicator]);

  useEffect(() => {
    if (withPlaceholder) {
      const newdisplayedData = [
        { [keyField]: -1, [labelField]: placeholder, value: null },
        ...data,
      ];
      setDisplayedData(newdisplayedData);
    } else {
      setDisplayedData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!isFocus) {
      releaseIndicator();
    } else {
      focusIndicator();
    }
  }, [isFocus]);

  function getSelectedIndex() {
    if (!selected) {
      return 0;
    }
    const index = displayedData.findIndex(
      (d) => d[keyField] === selected[keyField]
    );
    return index;
  }

  function getDisplayedItem(item) {
    if (typeof labelField === 'string') {
      return item[labelField];
    } else if (typeof labelField === 'function') {
      try {
        return labelField(item);
      } catch (e) {
        return withPlaceholder && item[keyField] === -1 ? placeholder : '';
      }
    }
  }

  function applyDangerIndicator() {
    selectRef.current.style.border = '1px solid red';
  }

  function releaseIndicator() {
    selectRef.current.style.border = 'none';
  }

  function focusIndicator() {
    selectRef.current.style.border = '1px solid #126133';
  }

  function onFocus() {
    setFocused(true);
  }

  function onBlur() {
    setFocused(false);
  }

  return (
    <div className={className} style={style}>
      {' '}
      {title && (
        <>
          <label htmlFor={id} className="form-label pe-2">
            {title}{' '}
          </label>
          {tooltip && (
            <Tooltip
              label={(() => (
                <img src={Info} width="101%" />
              ))()}
              tooltipContent={tooltip()}
            />
          )}
        </>
      )}
      <select
        ref={selectRef}
        className={`form-select ${errorText && 'error-input'}`}
        onChange={(e) => {
          const index = Number(e.target.value);
          const d = displayedData[index];
          onItemSelected(d);
          console.log('index -> ', index);
          if (index > 0) {
            setErrorText('');
          } else {
            setErrorText(errorTextProps);
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        value={getSelectedIndex()}
      >
        {displayedData.map((item, index) => (
          <option
            // selected={isSelected(item, index)}
            key={item[keyField] || index}
            value={index}
          >
            {getDisplayedItem(item)}
          </option>
        ))}
      </select>
      {errorText && <p className="text-danger text-error">{errorText}</p>}
    </div>
  );
}

InputSelect.defaultProps = {
  title: '',
  tooltip: '',
  id: '',
  data: [],
  keyField: 'id',
  labelField: 'label',
  ariaLabel: '',
  onItemSelected: () => {},
  className: '',
  style: {},
  selected: null,
  disabled: false,
  errorText: '',
  placeholder: '-- Pilih Data -- ',
  withPlaceholder: false,
  isDangerIndicator: false,
};

InputSelect.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.arrayOf(Object),
  keyField: PropTypes.string,
  ariaLabel: PropTypes.string,
  onItemSelected: PropTypes.func,
  labelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(Object),
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  withPlaceholder: PropTypes.bool,
  isDangerIndicator: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
