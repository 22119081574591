import React, { useEffect, useState } from 'react';
import { Label, Input, FormGroup, Row, Col } from 'reactstrap';
import './styles.scss';
import PropTypes from 'prop-types';
import { LoadingOnText } from '@volta/component/svg';

export default function RadioButton({
  data,
  title,
  defaultSelected,
  onRadioSelected,
  className,
  errorText: errorTextProps,
  disabled,
  isLoading,
}) {
  const [selectedRadio, setSelectedRadio] = useState(defaultSelected);
  const [displayedData, setDisplayedData] = useState(data);
  const [errorText, setErrorText] = useState(errorTextProps);

  function handleChange(e) {
    const { name, value } = e.target;
    setSelectedRadio({
      name: name,
      value: value,
    });
  }

  useEffect(() => {
    onRadioSelected(selectedRadio);
  }, []);

  useEffect(() => {
    onRadioSelected(selectedRadio);
  }, [selectedRadio]);

  useEffect(() => {
    setSelectedRadio(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  return (
    <div className={`volta-radio ${className}`}>
      {title && <label className="form-label">{title}</label>}
      {!isLoading ? (
        displayedData.map((item, index) => (
          <FormGroup check key={item.id} className="ms-3">
            <Input
              name={item.name}
              type="radio"
              value={item.value}
              // onChange={handleChange}
              onClick={handleChange}
              {...(item.id === defaultSelected.id
                ? { defaultChecked: true }
                : {})}
              {...(item.id === selectedRadio.id ? { checked: true } : {})}
              disabled={item['disabled'] || disabled}
            />{' '}
            <Label check>{item.label}</Label>
          </FormGroup>
        ))
      ) : (
        <LoadingOnText width="20" height="20" />
      )}
      {errorText && <p className="text-danger text-error mt-2">{errorText}</p>}
    </div>
  );
}

RadioButton.defaultProps = {
  errorText: '',
  disabled: false,
  isLoading: false,
};

RadioButton.propTypes = {
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};
