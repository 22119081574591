import { SET_COLORS } from '../extra-constants/color';

const colorInitialStates = {
  data: [],
  action: '',
};

function colorReducer(state = colorInitialStates, action) {
  const { payload, type } = action;
  const actions = {
    [SET_COLORS]: () => ({
      ...state,
      data: payload,
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { colorReducer };
