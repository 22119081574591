import { SET_HEADER_NAME, SET_OPEN_MENU } from './headerConstants';

export const setHeaderName = (payload) => ({
  payload,
  type: SET_HEADER_NAME,
});

export const setOpenMenu = (payload) => ({
  payload,
  type: SET_OPEN_MENU,
});
