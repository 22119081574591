import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHeaderName } from '@volta-component-layout/Header/headerActions';
import { NavLink } from 'react-router-dom';

function VoltaNavLink({ setHeaderName, headerName, ...props }) {
  return <NavLink onClick={() => setHeaderName(headerName)} {...props} />;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setHeaderName: (payload) => setHeaderName(payload),
};

VoltaNavLink.defaultProps = {
  headerName: '',
};

VoltaNavLink.propTypes = {
  setHeaderName: PropTypes.func.isRequired,
  headerName: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoltaNavLink);
